define("Automatons/automatons/2106", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2106,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_PublicSector",
      type: "guide",
      ariaEnabled: true,
      description: "CMRASI-2911",
      initialNode: "node::2455",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});