define("Automatons/automatons/2122", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2122,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_SUI_Offsite_Reload",
      ariaEnabled: true,
      description: "CMRGEN-26238",
      initialNode: "node::2084",
      onPageLanding: function onPageLanding() {
        var _this = this;

        if (api.automatonDataMap.hasOwnProperty('suiOffsiteConfig')) {
          try {
            (function () {
              var chatNode = _this.Automaton.getRecord("node::2084");
              var automatonConfig = api.automatonDataMap.suiOffsiteConfig;
              var ovfChatNode = _this.Automaton.getRecord("node::2472");
              var HOPNode = _this.Automaton.getRecord("node::2503");
              HOPNode.template = "template::2019";

              automatonConfig.forEach(function params(line, idx) {
                if (line.key === "bu") {
                  chatNode.chatRouter.businessUnit = line.value;
                }
                if (line.key === "ag") {
                  chatNode.chatRouter.agentGroup = line.value;
                }
                if (line.key === "qt") {
                  chatNode.chatRouter.qThreshold = line.value;
                  ovfChatNode.chatRouter.qThreshold = line.value;
                }
                if (line.key === "ovfbu") {
                  ovfChatNode.chatRouter.businessUnit = line.value;
                }
                if (line.key === "ovfag") {
                  ovfChatNode.chatRouter.agentGroup = line.value;
                }
              });
              if (ovfChatNode.chatRouter.businessUnit !== "" && ovfChatNode.chatRouter.agentGroup !== "") {
                chatNode.chatRouter.agentsBusyNode = "node::2472";
                chatNode.chatRouter.outsideHopNode = "node::2472";
              }
            })();
          } catch (e) {
            console.error("Error while configuring chat nodes: " + e);
          };
        }
      },
      style: "style::2028"
    }
  };
});