define("Automatons/nodes/2355", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2355,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_GigaCube - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006568,
        agentsBusyNode: "node::2356",
        businessUnit: 19001162,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});