define("Automatons/nodes/2020", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2020,
    type: "nodes",
    attributes: {
      name: "Vodafone Generic Service Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field"
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?<span class='asterisk'>*</span>",
          placeholder: "Enter text here"
        }, {
          id: "chat-reason",
          type: "select",
          label: "What would you like to chat to us about?<span class='asterisk'>*</span>",
          options: ["Pay Monthly account", "Pay as you go account", "Business account", "Technical - Broadband", "Broadband billing and account", "Broadband Activation", "Technical - Phone, Tablet & Watches", "Joining Vodafone", "I want to upgrade"],
          placeholder: "Choose item from the list",
          required: true,
          requiredError: "This is a required field"
        }, {
          id: "query",
          type: "textarea",
          dynamic: {
            check: "chat-reason",
            value: "I want to upgrade"
          },
          label: "In a few words, how can we help you today? (max 200 characters)<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is a required field",
          validate: {
            maxlength: 200
          }
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2011-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms['chat-reason'].indexOf('Pay Monthly account') > -1",
          target: "node::2021"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Pay as you go account') > -1",
          target: "node::2022"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Business account') > -1",
          target: "node::2617"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Broadband Activation') > -1",
          target: "node::2286"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Technical - Broadband') > -1",
          target: "node::2026"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Technical - Phone, Tablet & Watches') > -1",
          target: "node::2025"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Joining Vodafone') > -1",
          target: "node::2027"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I want to upgrade') > -1",
          target: "node::2506"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Broadband billing and account') > -1",
          target: "node::2636"
        }]
      }
    }
  };
});