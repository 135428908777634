define("Automatons/nodes/2403", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2403,
    type: "nodes",
    attributes: {
      name: "Vodafone Together Pre-Chat Survey - CSA-Acquisition - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006375,
        agentsBusyNode: "node::2424",
        businessUnit: 19001162,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.2
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});