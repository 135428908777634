define("Automatons/nodes/2124", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2124,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Retail - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "store-code",
          type: "text",
          label: "What is your store code?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please use numbers only"
          }
        }, {
          id: "requested-group",
          type: "select",
          label: "I would like to chat to<span class='asterisk'>*</span>",
          options: ["RETAIL ONLY - HALO ONLY Process Support", "PAYM and HBB Bill or Account Support", "Customer Mobile Technical Support", "Customer HBB Technical Support", "Customer PAYG Support", "Customer Porting Support", "HBB Process Support", "Support for Small and Medium Business Customers", "Anovo Escalations", "Credit Assessment", "Collections"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-type",
          type: "select",
          dynamic: {
            check: "requested-group",
            logic: "any",
            value: "RETAIL ONLY - HALO ONLY Process Support;PAYM and HBB Bill or Account Support;Customer Mobile Technical Support;Customer PAYG Support;Customer Porting Support;HBB Process Support;Support for Small and Medium Business Customers;Anovo Escalations"
          },
          label: "Query Type<span class='asterisk'>*</span>",
          options: ["Order Verification (Non Handset)", "ID Verification", "Address", "Credit Vet", "Order pricing issue (Non Handset)", "Create/recreate order (Non Handset)", "Services", "Other"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-type-credit-asses",
          type: "select",
          dynamic: {
            check: "requested-group",
            value: "Credit Assessment"
          },
          label: "Query Type<span class='asterisk'>*</span>",
          options: ["Consumer", "Business"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-order-verif",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Order Verification (Non Handset)"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Upgrade", "PAYG", "Pre to post", "Flexi Upgrade", "One Number", "Return", "Exchange", "Tariff migration", "SIM swap", "Add/Remove SOC", "Discounts", "HBB", "MBB", "DTS/CIS", "Insurance", "Red+ Leader/Sharer", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-id-verif",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "ID Verification"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Pre to post"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-address",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Address"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Pre to post", "Upgrade", "Flexi Upgrade", "Credit Vet", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-credit-vet",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Credit Vet"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Upgrade", "Pre to post", "Flexi upgrade", "Credit vet", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-order-pricing-issue",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Order pricing issue (Non Handset)"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Upgrade", "Flexi Upgrade", "Pre to post", "Return", "Exchange", "PAYG", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-create-order",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Create/recreate order (Non Handset)"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["Pending order", "Cancelled order"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-services",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Services"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["Service hierarchy- Merge", "Service hierarchy- Split", "SIM Swap", "Update IMEI", "Check IMEI", "Blacklist", "Billing", "Online account", "Price plan compatibility", "Upgrade Eligibility", "Commitment Date", "Disconnection/Reconnection", "Discounts", "Call Validate", "Add authorised contact", "Check order status"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-other",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Other"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["Data not working", "Entertainment pack", "Digital signature"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "New Connection"
          },
          label: "<span class='classHidden'>SCIO: Create a new connection for a new customer (KM1009201)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            logic: "any",
            value: "Additional line;One Number"
          },
          label: "<span class='classHidden'>SCIO: Create a New Connection for an Existing Customer (KM1008102)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            logic: "any",
            value: "Upgrade;Early Upgrade"
          },
          label: "<span class='classHidden'>SCIO: Upgrade a Customer (KM1006161)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "PAYG"
          },
          label: "<span class='classHidden'>SCIO: Create a New Pay As You Go Connection (KM1006204)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "Pre to post"
          },
          label: "<span class='classHidden'>SCIO: Pay As You Go or VOXI to Pay Monthly Migration (KM1008866)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "Flexi Upgrade"
          },
          label: "<span class='classHidden'>SCIO: Upgrade a customerKM1006161</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "Return"
          },
          label: "<span class='classHidden'>SCIO: Returns - Retail (KM1006812)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "Exchange"
          },
          label: "<span class='classHidden'>SCIO: Like for Like Exchange - Retail (KM1006810), Non Like for Like Exchange (KM1008637)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "Tariff migration"
          },
          label: "<span class='classHidden'>SCIO: Change a Plan (Flexible Phone Plans) (KM1009170)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "Add/Remove SOC"
          },
          label: "<span class='classHidden'>SCIO: Add a Product (KM1000425)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "HBB"
          },
          label: "<span class='classHidden'>SCIO: Create a new Home Broadband Connection (KM1007304)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "MBB"
          },
          label: "<span class='classHidden'>SCIO: Create a new connection for a new customer (KM1009201), Create a new Connection for an Existing Customer (KM1008102)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-order-verif",
            value: "DTS/CIS"
          },
          label: "<span class='classHidden'>SCIO: Retail Call and Collect (KM1008864)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-id-verif",
            logic: "any",
            value: "New Connection;Additional line;Pre to post"
          },
          label: "<span class='classHidden'>SCIO: IDV and Age Verification (KM1007753)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-address",
            value: "New Connection"
          },
          label: "<span class='classHidden'>SCIO: Create a New Connection for an Existing Customer (KM1008102)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-address",
            logic: "any",
            value: "Additional line;Pre to post;Upgrade;Flexi Upgrade;Credit Vet;Early Upgrade"
          },
          label: "<span class='classHidden'>SCIO: Address Change (KM1008610)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-credit-vet",
            value: "New Connection"
          },
          label: "<span class='classHidden'>SCIO: Create a new connection for a new customer (KM1009201)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-credit-vet",
            logic: "any",
            value: "Additional line;Upgrade;Pre to post;Flexi upgrade;Credit vet;Early Upgrade"
          },
          label: "<span class='classHidden'>SCIO: KYC and Credit Check Referrals (KM1009352)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-services",
            value: "Service hierarchy- Merge"
          },
          label: "<span class='classHidden'>SCIO: Merging a Service hierarchy (KM1011100)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-services",
            value: "Service hierarchy- Split"
          },
          label: "<span class='classHidden'>SCIO: Service Hierarchy Correction (KM1012550)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-services",
            value: "Blacklist"
          },
          label: "<span class='classHidden'>SCIO: Blacklist - Add (KM1000703)<br>SCIO: Blacklist - Remove or Check History (KM1000499)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-services",
            value: "Call Validate"
          },
          label: "<span class='classHidden'>SCIO: Call Validate (KM1008550)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-services",
            value: "Check order status"
          },
          label: "<span class='classHidden'>SCIO: Check an Order Status (KM1000704)</span>"
        }, {
          id: "query-sub-type-order-labels",
          type: "text",
          dynamic: {
            check: "query-sub-type-other",
            value: "Entertainment pack"
          },
          label: "<span class='classHidden'>SCIO: Entertainment Packs (DT1003754)</span>"
        }, {
          id: "issue-summary",
          type: "textarea",
          label: "Summary of the issue<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2038-name-label",
      onEntry: function onEntry() {
        var $viewport = api.automaton.Automaton.View.$viewport;
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }

        $($viewport).on('change', '.inq-2038-formify-input', function (e) {

          var selectValue = $($viewport).find('select[name="requested-group"]').val();

          if (selectValue == "RETAIL ONLY - HALO ONLY Process Support") {
            $('.inq-2038-classHidden, #inq-2038-query-sub-type-order-labels-label', $viewport).attr('style', 'display: block !important');
          } else {
            $('.inq-2038-classHidden, #inq-2038-query-sub-type-order-labels-label', $viewport).attr('style', 'display: none !important');
          }
        });
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['requested-group'].indexOf('RETAIL ONLY - HALO ONLY Process Support') > -1",
          target: "node::2125"
        }, {
          condition: "api.forms['requested-group'].indexOf('PAYM and HBB Bill or Account Support') > -1",
          target: "node::2126"
        }, {
          condition: "api.forms['requested-group'].indexOf('Customer Mobile Technical Support') > -1",
          target: "node::2127"
        }, {
          condition: "api.forms['requested-group'].indexOf('Customer PAYG Support') > -1",
          target: "node::2128"
        }, {
          condition: "api.forms['requested-group'].indexOf('Customer Porting Support') > -1",
          target: "node::2396"
        }, {
          condition: "api.forms['requested-group'].indexOf('HBB Process Support') > -1",
          target: "node::2129"
        }, {
          condition: "api.forms['requested-group'].indexOf('Support for Small and Medium Business Customers') > -1",
          target: "node::2130"
        }, {
          condition: "api.forms['requested-group'].indexOf('Anovo Escalations') > -1",
          target: "node::2135"
        }, {
          condition: "api.forms['requested-group'].indexOf('Credit Assessment') > -1 && api.forms['query-type-credit-asses'].indexOf('Consumer') > -1",
          target: "node::2486"
        }, {
          condition: "api.forms['requested-group'].indexOf('Credit Assessment') > -1 && api.forms['query-type-credit-asses'].indexOf('Business') > -1",
          target: "node::2487"
        }, {
          condition: "api.forms['requested-group'].indexOf('Collections') > -1",
          target: "node::2616"
        }, {
          condition: "api.forms['requested-group'].indexOf('Customer HBB Technical Support') > -1",
          target: "node::2601"
        }]
      }
    }
  };
});