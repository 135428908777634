define("Automatons/nodes/2348", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2348,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_MyVod_Login - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Phone Number",
          placeholder: "Type here",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "chat-reason",
          type: "select",
          hidden: true,
          label: "Chat Reason",
          options: [{
            label: "pay-monthly-account",
            selected: true,
            value: "pay-monthly-account"
          }]
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2076-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2349"
        }
      }
    }
  };
});