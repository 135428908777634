define("Automatons/nodes/2446", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2446,
    type: "nodes",
    attributes: {
      name: "Vodafone UK - App Direct Pre-Chat Survey - ESV-SBSales_AG - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006485,
        agentsBusyNode: "node::2013",
        businessUnit: 19001167,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.2
      },
      autoOpener: "Hello! How may I help you?",
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});