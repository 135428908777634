define("Automatons/nodes/2616", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2616,
    type: "nodes",
    attributes: {
      name: "Vodafone PreChat_Collections Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006360,
        agentsBusyNode: "node::2013",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.4
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});