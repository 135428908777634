define("Automatons/automatons/2067", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2067,
    type: "automatons",
    attributes: {
      name: "Vodafone Proactive Invite",
      ariaEnabled: true,
      description: "CMRASI-1985",
      initialNode: "node::2296",
      style: "style::2023"
    }
  };
});