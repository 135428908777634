define("Automatons/automatons/2153", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2153,
    type: "automatons",
    attributes: {
      name: "Vodafone - Smart Forms - Trade in Saving",
      description: "CMRGEN-27976",
      initialNode: "node::2093",
      panel: {
        width: 300
      },
      style: "style::2013"
    }
  };
});