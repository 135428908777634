define("Automatons/nodes/2230", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2230,
    type: "nodes",
    attributes: {
      name: "Vodafone Apple Watch - Call Now",
      type: "self-help",
      isOutcomeNode: 1,
      outcomeMessage: "Vodafone Apple Watch - Call Now",
      template: "template::2028"
    }
  };
});