define("Automatons/nodes/2334", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2334,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_FDS_Scheduling - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Remedy Change Requests - CRQ Refs') > -1",
          qThreshold: 999,
          target: "node::2329"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Soft Service Change Requests - VF/Q or C2/C6 Refs') > -1",
          qThreshold: 999,
          target: "node::2330"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Hard Provide & SCR Orders - Clarify/SOM/CPS') > -1",
          qThreshold: 999,
          target: "node::2331"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('POUTS') > -1",
          qThreshold: 999,
          target: "node::2332"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Customer Reporting Team') > -1",
          qThreshold: 999,
          target: "node::2333"
        }]
      }
    }
  };
});