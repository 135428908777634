define("Automatons/nodes/2280", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2280,
    type: "nodes",
    attributes: {
      name: "Vodafone Nano Widget FAQ PCS - Chat",
      type: "chat",
      chatRouter: {},
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});