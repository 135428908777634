define("Automatons/nodes/2341", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2341,
    type: "nodes",
    attributes: {
      name: "Vodafone Busy - No Polling",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2037"
    }
  };
});