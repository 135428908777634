define("Automatons/nodes/2618", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2618,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_VBPSupport Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field.",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[\\+\\s0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "business-sector",
          type: "select",
          label: "Business Sector<span class='asterisk'>*</span>",
          options: ["Fixed Foundation", "Non-Dedicated Fixed Desk", "Indirect Partners", "Converged", "VBP/OnePortal Faults"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query",
          type: "select",
          dynamic: {
            check: "business-sector",
            value: "Indirect Partners"
          },
          label: "What is the nature of your query?<span class='asterisk'>*</span>",
          options: ["Partners - Fixed Line", "Partners - Unified Comms"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what",
          type: "select",
          dynamic: {
            check: "business-sector",
            logic: "any",
            value: "Converged"
          },
          label: "What is this relating to?<span class='asterisk'>*</span>",
          options: ["Update on a Service Request", "Incident Request"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2062-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms['business-sector'].indexOf('Fixed Foundation') > -1",
          target: "node::2619"
        }, {
          condition: "api.forms['business-sector'].indexOf('Non-Dedicated Fixed Desk') > -1",
          target: "node::2620"
        }, {
          condition: "api.forms['business-sector'].indexOf('Converged') > -1 && api.forms['relating-to-what'].indexOf('Update on a Service Request') > -1",
          target: "node::2621"
        }, {
          condition: "api.forms['business-sector'].indexOf('Converged') > -1 && api.forms['relating-to-what'].indexOf('Incident Request') > -1",
          target: "node::2622"
        }, {
          condition: "api.forms['business-sector'].indexOf('Indirect Partners') > -1 && api.forms['query'].indexOf('Partners - Fixed Line') > -1",
          target: "node::2623"
        }, {
          condition: "api.forms['business-sector'].indexOf('Indirect Partners') > -1 && api.forms['query'].indexOf('Partners - Unified Comms') > -1",
          target: "node::2624"
        }, {
          condition: "api.forms['business-sector'].indexOf('VBP/OnePortal Faults') > -1",
          target: "node::2625"
        }]
      }
    }
  };
});