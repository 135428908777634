define("Automatons/nodes/2290", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2290,
    type: "nodes",
    attributes: {
      name: "Vodafone Broadband FAQ Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field"
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?",
          placeholder: "Enter text here"
        }, {
          id: "chat-reason",
          type: "select",
          label: "What would you like to chat to us about?<span class='asterisk'>*</span>",
          options: ["Broadband - Technical help", "Broadband - Account or bill", "Broadband - Activation", "Broadband - Buying or upgrading"],
          placeholder: "Choose item from the list",
          required: true,
          requiredError: "This is a required field"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2066-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['chat-reason'].indexOf('Broadband - Account or bill') > -1",
          target: "node::2291"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Broadband - Buying or upgrading') > -1",
          target: "node::2304"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Broadband - Activation') > -1",
          target: "node::2293"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Broadband - Technical help') > -1",
          target: "node::2292"
        }]
      }
    }
  };
});