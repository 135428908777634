define("Automatons/nodes/2296", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2296,
    type: "nodes",
    attributes: {
      name: "Vodafone Proactive Invite - Initial",
      type: "targetedOffer",
      onEntry: function onEntry() {
        var overlayVariation = api.automatonDataMap.OverlayVariation ? api.automatonDataMap.OverlayVariation : 1;
        var overlayPosition = api.automatonDataMap.OverlayPosition ? api.automatonDataMap.OverlayPosition : "";

        if (!overlayPosition) {
          switch (overlayVariation) {
            case 4:
            case 5:
            case 7:
              overlayPosition = "BOTTOM_RIGHT";
              break;
            default:
              overlayPosition = "CENTER";
          }
        }

        if (overlayVariation == 7) {
          this.viewport = {
            height: 248,
            width: 248
          };
        }

        api.IJSF.moveStage(overlayPosition);
        if (overlayPosition == "CENTER") {
          var $skin = ACIF.$skin;
          if ($skin.height() <= 2) {
            var _$skin$position = $skin.position();

            var left = _$skin$position.left;
            var _top = _$skin$position.top;

            var moveLeft = Math.max(left - 120, 0);
            var moveTop = Math.max(_top - 135, 0);
            api.IJSF.moveStage('ABSOLUTE', moveLeft, moveTop);
          }
        }
        api.hideLayer("titleBar");
        api.hideLayer("chatFooter");
        api.automatonDataMap.ShowProactiveOverlay = false;
      },
      onExit: function onExit() {
        api.hideLayer("acifCenter");
        api.hideLayer("chatFooter");
        api.hideLayer("toolBar");
      },
      template: "template::2031",
      viewport: {
        height: 270,
        width: 240
      }
    }
  };
});