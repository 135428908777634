define("Automatons/nodes/2178", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2178,
    type: "nodes",
    attributes: {
      name: "VF_PCS_ChannelPartners - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Channel Partner Support') > -1",
          qThreshold: 999,
          target: "node::2137"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Failed Connections team') > -1",
          qThreshold: 999,
          target: "node::2138"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('CEO escalations team') > -1",
          qThreshold: 999,
          target: "node::2139"
        }]
      }
    }
  };
});