define("Automatons/nodes/2339", ["exports", "module"], function (exports, module) {
      "use strict";

      module.exports = {
            id: 2339,
            type: "nodes",
            attributes: {
                  name: "VF_PreChat_CPW - Chat",
                  type: "chat",
                  chatRouter: {
                        agentGroup: 10006432,
                        agentsBusyNode: "node::2340",
                        businessUnit: 19001168,
                        checkAgentAvailability: true,
                        outsideHopNode: "node::2049",
                        qThreshold: 999
                  },
                  isOutcomeNode: 1,
                  onEntry: function onEntry() {
                        // Fire custom event for setting visitor attributes
                        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
                        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';

                        var visitorAttributes = {};

                        if (visitorName) {
                              visitorAttributes["Name"] = visitorName;
                        }
                        if (phoneNumber) {
                              visitorAttributes["CTN"] = phoneNumber;
                        }

                        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

                        // Include datapass variables in form data if present
                        var formData = api.getFormData();

                        var nanoRepIntent = typeof api.automatonDataMap.nanoRepIntent !== 'undefined' ? api.automatonDataMap.nanoRepIntent : '';

                        if (nanoRepIntent) {
                              formData = formData + '\n- nanoRepIntent: ' + nanoRepIntent;
                        }

                        // Setting customer name to use value in submitted form
                        var customerName = visitorName || 'You';
                        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
                        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
                        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

                        // Setting auto opener to nanoRepIntent if it exists, otherwise use default
                        var autoOpener = nanoRepIntent ? nanoRepIntent : "Thank you for choosing to chat with Vodafone. An agent will be with you shortly";

                        api.engageChat({
                              formData: formData,
                              autoOpener: autoOpener
                        });
                  }
            }
      };
});