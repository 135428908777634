define("Automatons/automatons/2119", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2119,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_FMP_Survey",
      ariaEnabled: true,
      description: "CMRGEN-20179",
      initialNode: "node::2501",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});