define("Automatons/nodes/2585", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2585,
    type: "nodes",
    attributes: {
      name: "Vodafone Free Rated Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field."
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "chat-reason",
          type: "select",
          hidden: true,
          label: "Chat Reason",
          options: [{
            label: "Offiste Default",
            selected: true,
            value: "Offsite Default"
          }]
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. To view our Privacy policy and Terms & conditions you will need to use a device which isn’t restricted by a data bar and has internet access.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2062-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
        if (api.automatonDataMap.hasOwnProperty('chatReason')) {
          this.formify.fields.forEach(function (field) {
            if (field.id == "chat-reason") {
              field.options[0].label = api.automatonDataMap.chatReason;
              field.options[0].value = api.automatonDataMap.chatReason;
            }
          });
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2586"
        }
      }
    }
  };
});