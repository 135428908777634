define("Automatons/nodes/2150", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2150,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessBroadband - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "customer-status",
          type: "select",
          label: "What would you like to talk to us about?<span class='asterisk'>*</span>",
          options: ["I'd like to buy from Vodafone", "I need help with my account"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-lines",
          type: "select",
          dynamic: {
            check: "customer-status",
            value: "I'd like to buy from Vodafone"
          },
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)", "10-49 (Mon-Fri, 9am-5pm)", "50-249 (Mon-Fri, 9am-5pm)", "250+"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2043-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms[\'customer-status\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)\') > -1",
          target: "node::2151"
        }, {
          condition: "api.forms[\'customer-status\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'10-49 (Mon-Fri, 9am-5pm)\') > -1",
          target: "node::2153"
        }, {
          condition: "api.forms[\'customer-status\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'50-249 (Mon-Fri, 9am-5pm)\') > -1",
          target: "node::2515"
        }, {
          condition: "api.forms[\'customer-status\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'250+\') > -1",
          target: "node::2483"
        }, {
          condition: "api.forms['customer-status'].indexOf('I need help with my account') > -1",
          target: "node::2453"
        }]
      }
    }
  };
});