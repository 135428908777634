define("Automatons/nodes/2434", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2434,
    type: "nodes",
    attributes: {
      name: "Vodafone UK - TOBi Outage - Dispatcher",
      type: "dispatcher",
      onEntryTransition: "routeToLiveAgents",
      transitions: {
        routeToLiveAgents: [{
          condition: "api.forms['chat-reason'].match(/pay monthly|pay-monthly-account/i)",
          target: "node::2435"
        }, {
          condition: "api.forms['chat-reason'].match(/pay as you go|pay-as-you-go/i)",
          target: "node::2436"
        }, {
          condition: "api.forms['chat-reason'].match(/small business|small-business/i)",
          target: "node::2437"
        }, {
          condition: "api.forms['chat-reason'].match(/upgrade/i) || api.forms['personal-chat-reason'].match(/upgrade/i)",
          target: "node::2438"
        }]
      }
    }
  };
});