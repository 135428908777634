define("Automatons/automatons/2152", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2152,
    type: "automatons",
    attributes: {
      name: "Vodafone - Smart Forms - Annual Upgrade Promise",
      description: "CMRGEN-27977",
      initialNode: "node::2091",
      panel: {
        width: 300
      },
      style: "style::2013"
    }
  };
});