define("Automatons/automatons/2041", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2041,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_VCO_Reactive",
      ariaEnabled: true,
      description: "CMRASI-1654",
      initialNode: "node::2142",
      proto: "automaton::2099",
      style: "style::2029"
    }
  };
});