define("Automatons/automatons/2157", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2157,
    type: "automatons",
    attributes: {
      name: "Vodafone - Smart Forms - Vulnerable Customers part 1",
      description: "CMRGEN-26368",
      initialNode: "node::2086",
      panel: {
        width: 300
      },
      style: "style::2011"
    }
  };
});