define("Automatons/nodes/2384", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2384,
    type: "nodes",
    attributes: {
      name: "Vodafone Broadband PCS - Get Help - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006334,
        agentsBusyNode: "node::2162",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.5,
        scriptTreeId: 12201267
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        api.fireCustomEvent('TobiUpgradeIntent', {
          TobiUpgradeIntent: "#Router-Greetings"
        });

        // Setting auto opener to nanoRepIntent if it exists, otherwise use default or the one defined in the chat node
        var autoOpener = this.autoOpener ? this.autoOpener : "Thanks for choosing to chat with us. An agent will be with you shortly";
        // if (nanoRepIntent) autoOpener = nanoRepIntent;

        // Datapass chat reason
        var chatReason = typeof api.forms['chat-reason'] !== 'undefined' ? api.forms['chat-reason'] : '';

        api.engageChat({
          formData: formData,
          autoOpener: autoOpener
        });
      },
      proto: "node::2183"
    }
  };
});