define("Automatons/automatons/2090", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2090,
    type: "automatons",
    attributes: {
      name: "Vodafone - File Upload",
      description: "CMRASI-2552",
      initialNode: "node::2397",
      panel: {
        width: 300
      },
      style: "style::2027"
    }
  };
});