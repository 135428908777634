define("Automatons/nodes/2570", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2570,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_ChannelSteering_NewUI - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "recommendation-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction with Vodafone Webchat, how likely are you to recommend Vodafone to your family and friends?",
          options: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0"],
          placeholder: "Select option"
        }, {
          id: "satisfaction-rate",
          type: "select",
          dynamic: {
            check: "recommendation-rate",
            logic: "any",
            value: "10;9;8;7;6;5;4;3;2;1;0"
          },
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, how satisfied are you overall with the service that our team member provided?",
          options: [{
            label: "10 - Best",
            value: "10"
          }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
            label: "0 - Worst",
            value: "0"
          }],
          placeholder: "Select option"
        }, {
          id: "query-resolved",
          type: "select",
          dynamic: {
            check: "satisfaction-rate",
            logic: "any",
            value: "10;9;8;7;6;5;4;3;2;1;0"
          },
          label: "Has the team member you have been chatting with resolved your query? (Yes / No)",
          options: ["Yes", "No"],
          placeholder: "Select option"
        }, {
          id: "chat-experience",
          type: "textarea",
          dynamic: {
            check: "query-resolved",
            logic: "any",
            value: "Yes;No"
          },
          label: "Please tell us a little about your chat experience today",
          placeholder: "Type message..."
        }],
        heading: "Thanks for chatting with us",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      template: "template::2032",
      transitions: {
        submit: {
          target: "node::2571"
        }
      }
    }
  };
});