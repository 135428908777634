define("Automatons/nodes/2146", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2146,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessMobile - 10+ Lines - Contact Us",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2025"
    }
  };
});