define("Automatons/automatons/2099", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2099,
    type: "automatons",
    attributes: {
      name: "Vodafone UK - Automaton Prototype",
      configChatNodes: function configChatNodes() {
        var _this = this;

        if (api.automatonDataMap.hasOwnProperty('automatonConfig')) {
          try {
            (function () {
              var automatonConfig = JSON.parse(api.automatonDataMap.automatonConfig);
              Object.keys(automatonConfig).forEach(function (nodeId) {
                try {
                  var nodeConfig = automatonConfig[nodeId];
                  var node = _this.Automaton.getRecord("node::" + nodeId);
                  if (nodeConfig["qt"]) {
                    node.chatRouter.qThreshold = nodeConfig["qt"];
                  }
                  if (nodeConfig["ag"]) {
                    node.chatRouter.agentGroup = nodeConfig["ag"];
                    console.log('auConfig AG:' + nodeConfig["ag"]);
                  }
                  if (nodeConfig["bu"]) {
                    node.chatRouter.businessUnit = nodeConfig["bu"];
                    console.log('auConfig BU:' + nodeConfig["bu"]);
                  }
                  if (nodeConfig["st"]) {
                    node.chatRouter.scriptTree = nodeConfig["st"];
                  }
                } catch (e) {
                  console.error("Error while configuring node " + nodeId + ": " + e);
                };
              });
            })();
          } catch (e) {
            console.error("Error while parsing automaton config: " + e);
          };
        }
        var chatNode = this.Automaton.getRecord("node::2589");
        var chatNode1 = this.Automaton.getRecord("node::2122");
        var brName = api.flashVars.brName;
        var businessRules = "VOD-C-LC-VOXI-O-R-Webchat-DT-C2C|VOD-C-LC-VOXI-O-R-Webchat-S-C2C|VOD-C-LC-Voxi-O-P-VOXINanorep-S-EXP2-C2C|VOD-C-LC-Voxi-O-P-VOXISavesOffsite-S-T0|VOD-C-LC-Voxi-O-P-VOXISavesOffsite-DT-T0|VOD-C-LC-Voxi-O-P-VOXINanorep-DT-EXP2-C2C|VOD-C-LC-Voxi-O-P-VOXINanorep-DT-EXP1-C2C|VOD-C-LC-Voxi-O-P-VOXINanorep-S-EXP1-C2C|VOD-C-LC-VOXI-O-R-Webchat-Offsite-DT|VOD-C-LC-VOXI-O-R-Webchat-Offsite-S";
        var regEx = new RegExp(businessRules, "gi");

        //console.log("current 2589 HoP: " + chatNode.chatRouter.outsideHopNode);
        //console.log("current 2122 HoP: " + chatNode1.chatRouter.outsideHopNode);
        if (brName.match(regEx) != null) {
          chatNode.chatRouter.outsideHopNode = "node::2599";
          chatNode1.chatRouter.outsideHopNode = "node::2599";
          //console.log("new HoP");
        }
        //console.log("current 2589 HoP2: " + chatNode.chatRouter.outsideHopNode);
        //console.log("current 2122 HoP2: " + chatNode1.chatRouter.outsideHopNode);
      },
      onPageLanding: function onPageLanding() {
        this.configChatNodes();
      }
    }
  };
});