define("Automatons/nodes/2555", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2555,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_VBP - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "chat-reason",
          type: "select",
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-49", "50-249"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2555-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms['chat-reason'].indexOf('1-49') > -1",
          target: "node::2556"
        }, {
          condition: "api.forms['chat-reason'].indexOf('50-249') > -1",
          target: "node::2537"
        }]
      }
    }
  };
});