define("Automatons/nodes/2575", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2575,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessMobile - CSV-SB_Heritage - Chat-AB-Test",
      type: "chat",
      chatRouter: {
        agentGroup: 10007017,
        agentsBusyNode: "node::2181",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.2
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        api.fireCustomEvent('TobiUpgradeIntent', {
          TobiUpgradeIntent: "#Router-Greetings"
        });
        // Fire custom event for setting visitor attributes
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';
        var emailAddress = typeof api.forms['email-address'] !== 'undefined' ? api.forms['email-address'] : '';
        var company = typeof api.forms['company'] !== 'undefined' ? api.forms['company'] : '';
        var companyName = typeof api.forms['company-name'] !== 'undefined' ? api.forms['company-name'] : '';

        var visitorAttributes = {};

        if (visitorName) {
          visitorAttributes["Name"] = visitorName;
        }
        if (phoneNumber) {
          visitorAttributes["CTN"] = phoneNumber;
        }
        if (emailAddress) {
          visitorAttributes["Email"] = emailAddress;
        }
        if (company) {
          visitorAttributes["Company_Name"] = company;
        }
        if (companyName) {
          visitorAttributes["Company_Name"] = companyName;
        }

        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

        // Include datapass variables in form data if present
        var formData = api.getFormData();

        var BPTIER = typeof api.automatonDataMap.BPTIER !== 'undefined' ? api.automatonDataMap.BPTIER : '';
        var VCO_REF = typeof api.automatonDataMap.VCO_REF !== 'undefined' ? api.automatonDataMap.VCO_REF : '';
        var nanoRepIntent = typeof api.automatonDataMap.nanoRepIntent !== 'undefined' ? api.automatonDataMap.nanoRepIntent : '';

        if (BPTIER) {
          formData = formData + '\n- BPTIER: ' + BPTIER;
        }
        if (VCO_REF) {
          formData = formData + '\n- VCO_REF: ' + VCO_REF;
        }
        if (nanoRepIntent) {
          formData = formData + '\n- nanoRepIntent: ' + nanoRepIntent;
        }

        // Setting customer name to use value in submitted form
        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        // Setting auto opener to nanoRepIntent if it exists, otherwise use default or the one defined in the chat node
        //  var autoOpener = this.autoOpener ? this.autoOpener : "Thanks for choosing to chat with us. An agent will be with you shortly";
        // if (nanoRepIntent) autoOpener = nanoRepIntent;

        // Datapass chat reason
        var chatReason = typeof api.forms['chat-reason'] !== 'undefined' ? api.forms['chat-reason'] : '';

        api.engageChat({
          formData: formData
        });
      },
      //     autoOpener: autoOpener
      proto: "node::2183"
    }
  };
});