define("Automatons/nodes/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "nodes",
    attributes: {
      name: "AP Video - Broadband Advert Initial",
      type: "agent-pushed",
      actions: {
        informAgentPressedClose: {
          action: "sendToAgent",
          params: "Customer pressed the close button."
        }
      },
      isOutcomeNode: 1,
      template: "template::2003",
      videoHeight: 320,
      videoWidth: 570,
      youtubeId: "ZcFaIpqpdk8"
    }
  };
});