define("Automatons/nodes/2239", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2239,
    type: "nodes",
    attributes: {
      name: "VOD-MyVod-PCS - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Pay Monthly account') > -1",
          qThreshold: 1,
          target: "node::2232"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Pay as you go account') > -1",
          qThreshold: 1,
          target: "node::2233"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Small business account') > -1",
          qThreshold: 1,
          target: "node::2234"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Home Broadband account') > -1",
          qThreshold: 1,
          target: "node::2235"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Technical - Phone, Tablet & Watches') > -1",
          qThreshold: 1.5,
          target: "node::2236"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Technical - Broadband') > -1",
          qThreshold: 1,
          target: "node::2237"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I want to upgrade') > -1",
          qThreshold: 1,
          target: "node::2238"
        }]
      }
    }
  };
});