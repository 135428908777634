define("Automatons/automatons/2125", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2125,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_TranscriptOnlyWhite",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRGEN-21140",
      initialNode: "node::2520",
      style: "style::2030"
    }
  };
});