define("Automatons/nodes/2181", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2181,
    type: "nodes",
    attributes: {
      name: "VF_PCS_BusinessMobile - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "(api.forms[\'chat-reason\'].indexOf(\'I want to join Vodafone\') > -1 || api.forms[\'chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 || api.forms[\'business-chat-reason\'].indexOf(\'I want to join Vodafone\') > -1) && (api.forms[\'number-of-lines\'].indexOf(\'1-9\') > -1 || api.forms[\'number-of-lines\'].indexOf(\'1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)\') > -1)",
          qThreshold: 1,
          target: "node::2145"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I need help with my Phone, Bill, or Account') > -1 || api.forms['business-chat-reason'].indexOf('I need help with my Phone, Bill, or Account') > -1",
          qThreshold: 1,
          target: "node::2148"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I want to upgrade') > -1 || api.forms['business-chat-reason'].indexOf('I want to upgrade') > -1",
          qThreshold: 1,
          target: "node::2149"
        }]
      }
    }
  };
});