define("Automatons/automatons/2136", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2136,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_VBP",
      ariaEnabled: true,
      description: "CMRGEN-23189",
      initialNode: "node::2555",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});