define("Automatons/nodes/2009", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2009,
    type: "nodes",
    attributes: {
      name: "AP - Secure Payment Form Initial",
      type: "agent-pushed",
      actions: {
        sendFormDataToAgent: {
          action: "sendFormDataToAgent"
        }
      },
      header: "",
      inputs: [{
        name: "Card_Type",
        label: "Card Type",
        mask: true,
        pattern: "^[a-zA-Z0-9 ]*$",
        placeholder: "Enter text here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }, {
        name: "CC_Number",
        display_masked: true,
        label: "Credit Card Number",
        mask: true,
        pattern: "^[0-9]*$",
        placeholder: "Enter number here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }, {
        name: "Exp_Date",
        label: "Expiration Date (MM/YY)",
        mask: true,
        pattern: "^(0[1-9]|1[0-2])\\/(18|19|2[0-9])$",
        placeholder: "Enter text here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }, {
        name: "CVV",
        label: "CVV",
        mask: true,
        pattern: "^[0-9]*$",
        placeholder: "Enter number here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }, {
        name: "House_Flat_Number",
        label: "House or Flat Number",
        mask: true,
        pattern: "^[a-zA-Z0-9 ]*$",
        placeholder: "Enter text here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }, {
        name: "Card_Postcode",
        label: "Card Postcode",
        mask: true,
        pattern: "^[a-zA-Z0-9 ]*$",
        placeholder: "Enter text here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }],
      onEntryTransition: "checkIfAllowed",
      sub_header: "This is a secure form. Details provided here are protected.",
      template: "template::2010",
      transitions: {
        checkIfAllowed: {
          condition: "api.get('showSecureForm') === false",
          target: "node::2471"
        }
      }
    }
  };
});