define("Automatons/automatons/2075", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2075,
    type: "automatons",
    attributes: {
      name: "Vodafone Broadband Offsite Engagement Pre-Chat Survey",
      ariaEnabled: true,
      description: "CMRASI-2157",
      initialNode: "node::2342",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});