define("Automatons/nodes/2240", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2240,
    type: "nodes",
    attributes: {
      name: "Vodafone Basic TobiApp Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field"
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?<span class='asterisk'>*</span>",
          placeholder: "Enter text here"
        }, {
          id: "chat-reason",
          type: "select",
          label: "What would you like help with?<span class='asterisk'>*</span>",
          options: ["Pay Monthly Account", "Pay As You Go Account", "Small Business Account", "Home Broadband Account", "Broadband Activation", "Technical - Phone, Tablet & Watches", "Technical - Broadband", "Joining Vodafone"],
          placeholder: "Choose item from the list",
          required: true,
          requiredError: "This is a required field"
        }, {
          id: "query",
          type: "textarea",
          label: "In a few words, how can we help you today? (max 200 characters)<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is a required field",
          validate: {
            maxlength: 200
          }
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2051-name-label",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.automatonDataMap.isTobiDevelopment === true && (api.forms['chat-reason'].indexOf('Pay Monthly Account') > -1 || api.forms['chat-reason'].indexOf('Pay As You Go Account') > -1)",
          target: "node::2268"
        }, {
          condition: "api.automatonDataMap.isTobiDevelopment === true && (api.forms['chat-reason'].indexOf('Small Business Account') > -1 || api.forms['chat-reason'].indexOf('Home Broadband Account') > -1 || api.forms['chat-reason'].indexOf('Technical - Phone, Tablet & Watches') > -1 || api.forms['chat-reason'].indexOf('Technical - Broadband') > -1 || api.forms['chat-reason'].indexOf('Joining Vodafone') > -1 || api.forms['chat-reason'].indexOf('Broadband Activation') > -1)",
          target: "node::2269"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Pay Monthly Account') > -1",
          target: "node::2241"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Pay As You Go Account') > -1",
          target: "node::2302"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Small Business Account') > -1",
          target: "node::2241"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Home Broadband Account') > -1",
          target: "node::2270"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Broadband Activation') > -1",
          target: "node::2299"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Technical - Phone, Tablet & Watches') > -1",
          target: "node::2271"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Technical - Broadband') > -1",
          target: "node::2272"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Joining Vodafone') > -1",
          target: "node::2273"
        }]
      }
    }
  };
});