define("Automatons/nodes/2524", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2524,
    type: "nodes",
    attributes: {
      name: "Vodafone_Business_PostChat_Desktop_initial_node",
      type: "survey",
      formify: {
        fields: [{
          id: "recommendation-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction, how likely are you to recommend Vodafone?",
          options: [{
            label: "10 - Best",
            value: "10"
          }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
            label: "0 - Worst",
            value: "0"
          }],
          placeholder: "Select option"
        }, {
          id: "satisfaction-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, how satisfied are you with the service that our team member provided?",
          options: [{
            label: "10 - Best",
            value: "10"
          }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
            label: "0 - Worst",
            value: "0"
          }],
          placeholder: "Select option"
        }, {
          id: "query-resolved",
          type: "select",
          label: "Has the team member you have been chatting with resolved or committed to resolve your query? ( Yes / No )",
          options: ["Yes", "No"],
          placeholder: "Select option"
        }, {
          id: "transcript-email",
          type: "text",
          label: "Please enter your email address below if you would like to receive a copy of your conversation today.",
          placeholder: "Email address",
          validate: {
            pattern: "^$|^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,65})$",
            patternError: "Please enter a valid e-mail address"
          },
          value: ""
        }, {
          id: "feedback",
          type: "textarea",
          label: "Please tell us a little about your chat experience today",
          placeholder: "Type message..."
        }],
        heading: "Thanks for chatting with us",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      template: "template::2006",
      transitions: {
        submit: {
          target: "node::2525"
        }
      }
    }
  };
});