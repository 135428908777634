define("Automatons/nodes/2301", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2301,
    type: "nodes",
    attributes: {
      name: "Vodafone Basic TobiApp PCS - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Home Broadband account') > -1",
          qThreshold: 1.5,
          target: "node::2270"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Broadband Activation') > -1",
          qThreshold: 1.5,
          target: "node::2300"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Technical - Broadband') > -1",
          qThreshold: 1.5,
          target: "node::2272"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Pay As You Go Account') > -1",
          qThreshold: 1.5,
          target: "node::2302"
        }]
      }
    }
  };
});