define("Automatons/automatons/2052", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2052,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_Basic_Mob",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-1872",
      initialNode: "node::2248",
      style: "style::2022"
    }
  };
});