define("Automatons/nodes/2221", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2221,
    type: "nodes",
    attributes: {
      name: "VF_PCS_VCO_Reactive - ESV-VCO_Login - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          qThreshold: 1,
          target: "node::2220"
        }]
      }
    }
  };
});