define("Automatons/nodes/2604", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2604,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Offsite_New - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "Could you please tell me your name?",
          placeholder: "Type here",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Could you please tell me your phone number?",
          placeholder: "Type here",
          validate: {
            pattern: "^[0-9 ]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "chat-reason",
          type: "select",
          label: "What does your query relate to?<span class='asterisk'>*</span>",
          options: ["A Pay Monthly Contract", "Pay as You Go or VOXI", "Home Broadband or Landline", "A Business account"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what",
          type: "select",
          dynamic: {
            check: "chat-reason",
            logic: "any",
            value: "A Pay Monthly Contract"
          },
          label: "What type of account do you have with us?<span class='asterisk'>*</span>",
          options: ["I have a Phone, Bill or Account query", "I have a technical issue with the network or my device", "I want to buy or upgrade", "I want to cancel"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what",
          type: "select",
          dynamic: {
            check: "chat-reason",
            logic: "any",
            value: "Home Broadband or Landline"
          },
          label: "What type of account do you have with us?<span class='asterisk'>*</span>",
          options: ["I want to buy Home Broadband", "I have a Home Broadband activation or engineer visit query", "Broadband technical", "Broadband bill or account", "Mobile broadband or Gigacube"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "",
        headerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Please provide your name and phone number below so we can transfer you to a live agent",
        next: "submit"
      },
      initialFocusElement: "#inq-2604-name-label",
      onEntry: function onEntry() {
        var automatonId = api.automaton.id;
        var $viewport = api.automaton.Automaton.View.$viewport;
        var obsTarget = $viewport[0];

        function createUIElements() {
          var $fields = $('input', $viewport);
          var $submitBtn = $('.inq-' + automatonId + '-formify-submit', $viewport);
          var $selectBtn = $($viewport).find('fieldset[acif-field-name="chat-reason"]');

          var goToNextField = function goToNextField() {
            var pattern = new RegExp($(this).prev().attr("pattern"));
            if (!pattern.test($(this).prev().val())) {
              return;
            }
            var idx = $(this).data("index");
            var $ansContainer = $("<div></div>").addClass('inq-' + automatonId + '-formify-answerContainer');
            var $answer = $("<div></div>").addClass('inq-' + automatonId + '-formify-answer').text($(this).prev().val());
            $(this).prev().hide();
            $(this).hide();
            $(this).parent().parent().after($ansContainer.append($answer));
            if ($fields.length - 1 > idx) {
              $($fields[idx + 1]).parent().show();
              $($fields[idx + 1]).focus();
            } else {
              $submitBtn.removeAttr("disabled").show();
              $selectBtn.removeAttr("disabled").show();
            }
          };

          $submitBtn.attr("disabled", true).hide();
          $selectBtn.attr("disabled", true).hide();
          $fields.each(function (index) {
            $(this).keyup(function (e) {
              var $btnNext = $(this).next();
              if ($(this).val().length === 0) {
                $btnNext.attr("disabled", "disabled");
              } else {
                $btnNext.removeAttr('disabled');
                if (event.which == 13) {
                  $btnNext.click();
                }
              }
            });
            var button = $("<button type='button'></button").addClass('inq-' + automatonId + '-formify-next-btn').text("Next").data("index", index).attr("disabled", "disabled").click(goToNextField);
            $(this).after(button);

            if (index != 0) {
              $(this).parent().hide();
            }
          });
        }

        var observer = new MutationObserver(function (mutations, observer) {
          var inputFieldsCount = $('input', $viewport).length;
          var btnCount = $('button', $viewport).length;
          if (inputFieldsCount > 0 && btnCount > 0) {
            createUIElements();
            observer.disconnect();
          }
        });
        observer.observe(obsTarget, {
          subtree: true,
          childList: true
        });
      },
      proto: "node::2411",
      template: "template::2046",
      transitions: {
        submit: [{
          condition: "api.forms['chat-reason'].indexOf('Pay as You Go or VOXI') > -1",
          target: "node::2605"
        }, {
          condition: "api.forms['chat-reason'].indexOf('A Business account') > -1",
          target: "node::2606"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('I have a Phone, Bill or Account query') > -1",
          target: "node::2607"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('I have a technical issue with the network or my device') > -1",
          target: "node::2608"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('I want to buy or upgrade') > -1",
          target: "node::2609"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('I want to cancel') > -1",
          target: "node::2610"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('I want to buy Home Broadband') > -1",
          target: "node::2611"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('I have a Home Broadband activation or engineer visit query') > -1",
          target: "node::2612"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('Broadband technical') > -1",
          target: "node::2613"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('Mobile broadband or Gigacube') > -1",
          target: "node::2614"
        }, {
          condition: "api.forms['relating-to-what'].indexOf('Broadband bill or account') > -1",
          target: "node::2632"
        }]
      }
    }
  };
});