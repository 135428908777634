define("Automatons/nodes/2264", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2264,
    type: "nodes",
    attributes: {
      name: "VF_BBApp_prechat - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field."
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?",
          placeholder: "Enter text here"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2061-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2265"
        }
      }
    }
  };
});