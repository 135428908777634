define("Automatons/nodes/2571", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2571,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_ChannelSteering_NewUI - Thank You",
      type: "thankyou",
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        api.setTimeout(function () {
          window.top.close();
        }, 2000);
      },
      outcomeMessage: "User has submitted postchat feedback.",
      template: "template::2036"
    }
  };
});