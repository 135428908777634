define("Automatons/nodes/2159", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2159,
    type: "nodes",
    attributes: {
      name: "Segmented Service PCS - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Pay Monthly account') > -1",
          qThreshold: 1.5,
          target: "node::2043"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Pay as you go account') > -1",
          qThreshold: 1.5,
          target: "node::2044"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Small business account') > -1",
          qThreshold: 1.5,
          target: "node::2045"
        }]
      }
    }
  };
});