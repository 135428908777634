define("Automatons/nodes/2482", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2482,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_PublicSector - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "query",
          type: "select",
          label: "What is your query about?<span class='asterisk'>*</span>",
          options: ["Personal Pay monthly plan", "Small or Medium-sized business plan"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "personal-chat-reason",
          type: "select",
          dynamic: {
            check: "query",
            value: "Personal Pay monthly plan"
          },
          label: "What would you like to talk to us about?<span class='asterisk'>*</span>",
          options: ["I want to upgrade", "Track my order", "I'd like to buy from Vodafone", "Help me with my phone, bill, or account"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "business-chat-reason",
          type: "select",
          dynamic: {
            check: "query",
            value: "Small or Medium-sized business plan"
          },
          label: "What would you like to talk to us about?<span class='asterisk'>*</span>",
          options: ["I'd like to buy from Vodafone", "Help me with my phone, bill, or account", "I want to upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-lines",
          type: "select",
          dynamic: {
            check: "business-chat-reason",
            logic: "any",
            value: "I'd like to buy from Vodafone"
          },
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)", "10-49 (Mon-Fri, 9am-5pm)", "50-249 (Mon-Fri, 9am-5pm)", "250+"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-lines",
          type: "select",
          dynamic: {
            check: "business-chat-reason",
            logic: "any",
            value: "I want to upgrade"
          },
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)", "10-49", "50-249", "250+"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "chat-reason",
          type: "select",
          hidden: true,
          label: "Chat Reason",
          options: [{
            label: "hidden",
            selected: true,
            value: "hidden"
          }]
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2042-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms[\'query\'].indexOf(\'Small or Medium-sized business plan\') > -1 && api.forms[\'business-chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)\') > -1",
          target: "node::2145"
        }, {
          condition: "api.forms[\'query\'].indexOf(\'Small or Medium-sized business plan\') > -1 && api.forms[\'business-chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'10-49 (Mon-Fri, 9am-5pm)\') > -1",
          target: "node::2399"
        }, {
          condition: "api.forms[\'query\'].indexOf(\'Small or Medium-sized business plan\') > -1 && api.forms[\'business-chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'250+\') > -1",
          target: "node::2483"
        }, {
          condition: "api.forms[\'query\'].indexOf(\'Small or Medium-sized business plan\') > -1 && api.forms[\'business-chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'50-249 (Mon-Fri, 9am-5pm)\') > -1",
          target: "node::2537"
        }, {
          condition: "api.forms['query'].indexOf('Small or Medium-sized business plan') > -1 && api.forms['business-chat-reason'].indexOf('Help me with my phone, bill, or account') > -1",
          target: "node::2148"
        }, {
          condition: "api.forms['query'].indexOf('Small or Medium-sized business plan') > -1 && api.forms['business-chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)') > -1",
          target: "node::2149"
        }, {
          condition: "api.forms['query'].indexOf('Small or Medium-sized business plan') > -1 && api.forms['business-chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('10-49') > -1",
          target: "node::2587"
        }, {
          condition: "api.forms['query'].indexOf('Small or Medium-sized business plan') > -1 && api.forms['business-chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('250+') > -1",
          target: "node::2483"
        }, {
          condition: "api.forms['query'].indexOf('Small or Medium-sized business plan') > -1 && api.forms['business-chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('50-249') > -1",
          target: "node::2587"
        }, {
          condition: "api.forms['query'].indexOf('Personal Pay monthly plan') > -1 && api.forms['personal-chat-reason'].indexOf('Track my order') > -1",
          target: "node::2382"
        }, {
          condition: "api.forms[\'query\'].indexOf(\'Personal Pay monthly plan\') > -1 && api.forms[\'personal-chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1",
          target: "node::2498"
        }, {
          condition: "api.forms['query'].indexOf('Personal Pay monthly plan') > -1 && api.forms['personal-chat-reason'].indexOf('Help me with my phone, bill, or account') > -1",
          target: "node::2384"
        }, {
          condition: "api.forms['query'].indexOf('Personal Pay monthly plan') > -1 && api.forms['personal-chat-reason'].indexOf('I want to upgrade') > -1",
          target: "node::2506"
        }]
      }
    }
  };
});