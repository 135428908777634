define("Automatons/nodes/2015", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2015,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_Service_initial_node",
      type: "survey",
      formify: {
        fields: [{
          id: "recommendation-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction with Vodafone Webchat, how likely are you to recommend Vodafone to your family and friends?",
          options: [{
            label: "10 - Extremely likely",
            value: "10"
          }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
            label: "0 - Not at all likely",
            value: "0"
          }],
          placeholder: "Select option"
        }, {
          id: "satisfaction-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, how satisfied are you overall with the service that our team member provided?",
          options: [{
            label: "10 - Best",
            value: "10"
          }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
            label: "0 - Worst",
            value: "0"
          }],
          placeholder: "Select option"
        }, {
          id: "query-resolved",
          type: "select",
          label: "Has the team member you have been chatting with resolved your query? ( Yes / No )",
          options: ["Yes", "No"],
          placeholder: "Select option"
        }, {
          id: "transcript-email",
          type: "text",
          label: "Please enter your email address below if you would like to receive a copy of your conversation today.",
          placeholder: "Email address",
          validate: {
            pattern: "^$|^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,65})$",
            patternError: "Please enter a valid e-mail address"
          },
          value: ""
        }, {
          id: "chat-experience",
          type: "textarea",
          label: "Please tell us a little about your chat experience today.",
          placeholder: "Type message..."
        }],
        heading: "Thanks for chatting with us",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      onEntry: function onEntry() {
        var transcript = window.top.inqFrame.Inq.PM.getVar('emailTranscript').z0();
        var emailAddress = window.top.nuanceData.CUSTOMER_EMAIL;
        //console.log("emailAddress: " + emailAddress);
        //console.log("consent: " + transcript);
        if (transcript == true) {
          this.formify.fields[3].hidden = true;
          this.formify.fields[3].value = "";
          api.fireCustomEvent('UnSetTranscriptEmail');
        } else {
          this.formify.fields[3].hidden = false;
          //this.formify.fields[3].value = emailAddress;
        }
      },
      template: "template::2006",
      transitions: {
        submit: {
          target: "node::2016"
        }
      }
    }
  };
});