define("Automatons/nodes/2005", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2005,
    type: "nodes",
    attributes: {
      name: "AP - Dynamic Form - Rows",
      type: "agent-pushed",
      Form_ID: 1234,
      isOutcomeNode: 1,
      items: [{
        active: false,
        description: "Unlimited Freedom with 10GB hotspot - $xx.xx",
        label1: "Katrina's iPhone",
        label2: "iPhone 6S (123) 456-7890",
        value: "Katrina's iPhone"
      }, {
        description: "Unlimited Freedom with 10GB hotspot - $xx.xx",
        label1: "Amy's Secret iPhone",
        label2: "iPhone 6S (123) 456-7890",
        value: "Amy's Secret iPhone"
      }, {
        description: "Unlimited Freedom with 10GB hotspot - $xx.xx",
        label1: "Justin's Galaxy 6",
        value: "Justin's iPhone"
      }],
      onEntry: function onEntry() {
        setTimeout(checkContainer, 300);

        function checkContainer() {
          var $viewport = api.automaton.Automaton.View.$viewport;
          var containerHeight = $('.dynamic-container,.inq-2004-dynamic-container', $viewport).outerHeight();
          var headerHeight = $('#header,#inq-2004-header', $viewport).outerHeight();
          var footerHeight = $('.footer-buttons,.inq-2004-footer-buttons', $viewport).outerHeight();

          var desiredHeight = containerHeight + headerHeight + footerHeight + 20;

          if (desiredHeight < 300) {
            api.IJSF.setElementPosition('acifChatFooter', {
              height: desiredHeight
            });
          }
        }
      },
      onExit: function onExit() {
        var formData = api.getAllFormData();
        var systemDatapass = toDatapassString(formData);

        function toDatapassString(formDataObj) {
          var datapassArray = [];
          for (var question in formDataObj) {
            if (formDataObj.hasOwnProperty(question) && formDataObj[question] != '') {
              datapassArray.push('- ' + formDataObj[question]);
            }
          }
          return datapassArray.join('<br/>');
        }
        api.IJSF.sendSystemMessage(systemDatapass, "successful");
        api.sendFormDataToAgent();
      },
      template: "template::2004",
      title: "Pick a device"
    }
  };
});