define("Automatons/nodes/2411", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2411,
    type: "nodes",
    attributes: {
      name: "Vodafone UK - Prechat Survey Prototype",
      type: "Prototype",
      onExit: function onExit() {
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';
        var emailAddress = typeof api.forms['email-address'] !== 'undefined' ? api.forms['email-address'] : '';
        var company = typeof api.forms['company'] !== 'undefined' ? api.forms['company'] : '';
        var companyName = typeof api.forms['company-name'] !== 'undefined' ? api.forms['company-name'] : '';
        var chatReason = typeof api.forms['chat-reason'] !== 'undefined' ? api.forms['chat-reason'] : '';
        var personalchatReason = typeof api.forms['personal-chat-reason'] !== 'undefined' ? api.forms['personal-chat-reason'] : '';
        var businesschatReason = typeof api.forms['business-chat-reason'] !== 'undefined' ? api.forms['business-chat-reason'] : '';
        var enquirychatReason = typeof api.forms['enquiry-chat-reason'] !== 'undefined' ? api.forms['enquiry-chat-reason'] : '';
        var querychatReason = typeof api.forms['query-chat-reason'] !== 'undefined' ? api.forms['query-chat-reason'] : '';
        var relatingtoWhat = typeof api.forms['relating-to-what'] !== 'undefined' ? api.forms['relating-to-what'] : '';

        var visitorAttributes = {};
        if (visitorName) {
          visitorAttributes["Name"] = visitorName;
        }
        if (phoneNumber) {
          visitorAttributes["CTN"] = phoneNumber;
        }
        if (emailAddress) {
          visitorAttributes["Email"] = emailAddress;
        }
        if (company) {
          visitorAttributes["Company_Name"] = company;
        }
        if (companyName) {
          visitorAttributes["Company_Name"] = companyName;
        }
        if (chatReason) {
          visitorAttributes["ChatReason"] = chatReason;
        }
        if (personalchatReason) {
          visitorAttributes["ChatReason"] = personalchatReason;
        }
        if (businesschatReason) {
          visitorAttributes["ChatReason"] = businesschatReason;
        }
        if (enquirychatReason) {
          visitorAttributes["ChatReason"] = enquirychatReason;
        }
        if (querychatReason) {
          visitorAttributes["ChatReason"] = querychatReason;
        }
        if (relatingtoWhat) {
          visitorAttributes["ChatReason"] = relatingtoWhat;
        }
        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

        api.fireCustomEvent('UpdateChatReason', {
          chatReason: chatReason
        });

        var map = JSON.stringify({
          "name": visitorName,
          "CTN": phoneNumber
        });
        try {
          window.setAutomatonDataMap(map);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };
});