define("Automatons/automatons/2147", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2147,
    type: "automatons",
    attributes: {
      name: "VF_AutoClose_NoMessages",
      description: "CMRGEN-25189",
      initialNode: "node::2591",
      style: "style::2033"
    }
  };
});