define("Automatons/nodes/2086", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2086,
    type: "nodes",
    attributes: {
      name: "Vodafone - Smart Forms - Vulnerable Customers part 1 - Initial",
      type: "agent-pushed",
      formify: {
        fields: [{
          id: "vulnerabilities",
          type: "select",
          label: "To help us support and communicate with you, do you have any personal circumstances that we need to take into account when communicating with you? An example of such circumstances could be, a health condition, reading difficulties or a recent bereavement.",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "vulnerability",
          type: "text",
          dynamic: {
            check: "vulnerabilities",
            logic: "any",
            value: "Yes"
          },
          label: "Please give more details?",
          placeholder: "Type here"
        }],
        next: "submit"
      },
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        //if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
        //api.resize(300, 490);
        //}
        var msg_to_agent = "{{**Read the vulnerable customers script. Customer consent must be obtained prior to registering any vulnerabilities in Halo. You must ensure that this is registered exactly as described. Also ensure that any special billing or repair requirements are registered. For help in identifying vulnerabilities, follow the Working Instructions.**}}";
        Inq.sendAutomatonMessage('datapass', {
          IMPORTANT: msg_to_agent
        });
        api.IJSF.sendDatapassMessage({
          'IMPORTANT': msg_to_agent
        });
      },
      template: "template::2063",
      transitions: {
        submit: [{
          condition: "api.forms['vulnerabilities'].indexOf('No') > -1",
          target: "node::2087"
        }, {
          condition: "api.forms['vulnerabilities'].indexOf('Yes') > -1",
          target: "node::2090"
        }]
      }
    }
  };
});