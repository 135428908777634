define("Automatons/nodes/2556", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2556,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessMobile - ESC-SMB-10-50 - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006928,
        agentsBusyNode: "node::2181",
        businessUnit: 19001167,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});