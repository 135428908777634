define("Automatons/nodes/2458", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2458,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Complaints - Redirect",
      type: "redirect",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Redirected to complaints.vodafone.co.uk",
      redirectTarget: "current",
      redirectUrl: "https://complaints.vodafone.co.uk/resource?id=11306244&db=vodafone"
    }
  };
});