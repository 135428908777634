define("Automatons/nodes/2471", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2471,
    type: "nodes",
    attributes: {
      name: "AP Form - Secure Form Disabled",
      type: "exit",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Exited from the Secure Form because the AG is not in the allowed list."
    }
  };
});