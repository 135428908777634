define("Automatons/automatons/2149", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2149,
    type: "automatons",
    attributes: {
      name: "Vodafone - Smart Forms - Vulnerable Customers part 2",
      description: "CMRGEN-26368",
      initialNode: "node::2088",
      panel: {
        width: 300
      },
      style: "style::2013"
    }
  };
});