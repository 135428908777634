define("Automatons/nodes/2528", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2528,
    type: "nodes",
    attributes: {
      name: "Vodafone VOIS Credit Assessment Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "ban-number",
          type: "text",
          label: "BAN Number",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field."
        }, {
          id: "business-name",
          type: "text",
          label: "Business Name",
          placeholder: "Enter text here"
        }, {
          id: "quantity",
          type: "select",
          label: "How many orders do you want to place? <span class='asterisk'>*</span>",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "name-of-line-1",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "1;2;3;4;5;6;7;8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-1",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "1;2;3;4;5;6;7;8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-1",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "1;2;3;4;5;6;7;8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-2",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "2;3;4;5;6;7;8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-2",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "2;3;4;5;6;7;8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-2",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "2;3;4;5;6;7;8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-3",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "3;4;5;6;7;8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-3",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "3;4;5;6;7;8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-3",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "3;4;5;6;7;8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-4",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "4;5;6;7;8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-4",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "4;5;6;7;8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-4",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "4;5;6;7;8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-5",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "5;6;7;8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-5",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "5;6;7;8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-5",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "5;6;7;8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-6",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "6;7;8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-6",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "6;7;8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-6",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "6;7;8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-7",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "7;8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-7",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "7;8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-7",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "7;8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-8",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "8;9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-8",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "8;9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-8",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "8;9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "name-of-line-9",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "9"
          },
          label: "Name of line/lines being added (up to a max of 9 per order)",
          placeholder: "Enter text here"
        }, {
          id: "quantity-of-line-9",
          type: "select",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "9"
          },
          label: "Quantity of those lines (up to a max of 9 per order)",
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "price-of-line-9",
          type: "text",
          dynamic: {
            check: "quantity",
            logic: "any",
            value: "9"
          },
          label: "The price of each line",
          placeholder: "Enter text here"
        }, {
          id: "chat-reason",
          type: "select",
          hidden: true,
          label: "Chat Reason"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2062-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
        if (api.automatonDataMap.hasOwnProperty('chatReason')) {
          this.formify.fields.forEach(function (field) {
            if (field.id == "chat-reason") {
              field.options[0].label = api.automatonDataMap.chatReason;
              field.options[0].value = api.automatonDataMap.chatReason;
            }
          });
        }
      },
      proto: "node::2411",
      template: "template::2054",
      transitions: {
        submit: {
          target: "node::2487"
        }
      }
    }
  };
});