define("Automatons/automatons/2014", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2014,
    type: "automatons",
    attributes: {
      name: "Vodafone Sales Pre-Chat Survey",
      ariaEnabled: true,
      description: "CMRASI-1624",
      initialNode: "node::2037",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});