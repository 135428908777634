define("Automatons/nodes/2362", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2362,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_OneNet - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['requested-group'].indexOf('One Net Business Customer Care Team') > -1",
          qThreshold: 1,
          target: "node::2358"
        }, {
          AA: true,
          condition: "api.forms[\'requested-group\'].indexOf(\'One Net Business TAP\\\'s Team\') > -1",
          qThreshold: 1,
          target: "node::2359"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('One Net Business MACD Team') > -1",
          qThreshold: 1,
          target: "node::2360"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('One Net Business Complex MACD Team') > -1",
          qThreshold: 1,
          target: "node::2361"
        }]
      }
    }
  };
});