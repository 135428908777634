define("Automatons/nodes/2444", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2444,
    type: "nodes",
    attributes: {
      name: "Vodafone UK - App Direct Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ']*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "existing-account-or-application",
          type: "select",
          label: "Do you want to talk to us about your existing Vodafone Business Applications or Marketplace account?<span class='asterisk'>*</span>",
          options: ["Yes", "No"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "chat-reason",
          type: "select",
          dynamic: {
            check: "existing-account-or-application",
            value: "Yes"
          },
          label: "What would you like to talk to us about?<span class='asterisk'>*</span>",
          options: ["I need help with my business applications", "I need help with Vodafone Business Marketplace", "I need help with my bill", "I need help with my account", "I want to buy more business applications", "I want to update my licence(s)"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-employees",
          type: "select",
          dynamic: {
            check: "existing-account-or-application",
            value: "No"
          },
          label: "I want to buy & set-up business applications from Vodafone<span class='asterisk'>*</span>",
          options: ["1 to 9 employees", "10+ employees"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2102-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['existing-account-or-application'].indexOf('Yes') > -1",
          target: "node::2445"
        }, {
          condition: "api.forms['existing-account-or-application'].indexOf('No') > -1 && api.forms['number-of-employees'].indexOf('1 to 9 employees') > -1",
          target: "node::2446"
        }, {
          condition: "api.forms['existing-account-or-application'].indexOf('No') > -1 && api.forms['number-of-employees'].indexOf('10+ employees') > -1",
          target: "node::2447"
        }]
      }
    }
  };
});