define("Automatons/automatons/2151", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2151,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_Offsite_New",
      ariaEnabled: true,
      description: "CMRGEN-27412",
      initialNode: "node::2604",
      proto: "automaton::2099",
      style: "style::2028"
    }
  };
});