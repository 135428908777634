define("Automatons/automatons/2129", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2129,
    type: "automatons",
    attributes: {
      name: "VF_Name_EmailOnly",
      ariaEnabled: true,
      description: "CMRGEN-21712",
      initialNode: "node::2529",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});