define("Automatons/automatons/2126", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2126,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_TranscriptOnlyWhiteMob",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRGEN-21140",
      initialNode: "node::2522",
      style: "style::2030"
    }
  };
});