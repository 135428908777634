define("Automatons/nodes/2173", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2173,
    type: "nodes",
    attributes: {
      name: "Basic Upgrades PCS - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          qThreshold: 1,
          target: "node::2108"
        }]
      }
    }
  };
});