define("Automatons/automatons/2135", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2135,
    type: "automatons",
    attributes: {
      name: "Nanorep Transition Copy",
      description: "CMRGEN-22978",
      initialNode: "node::2554"
    }
  };
});