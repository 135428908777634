define("Automatons/nodes/2172", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2172,
    type: "nodes",
    attributes: {
      name: "VF_PCS_NED - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Engineer Job Request (Provide/Cease)') > -1",
          qThreshold: 999,
          target: "node::2090"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Engineer Job Request (Assure)') > -1",
          qThreshold: 999,
          target: "node::2091"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Engineer General Query/Admin') > -1",
          qThreshold: 999,
          target: "node::2092"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Standard Order Management') > -1",
          qThreshold: 999,
          target: "node::2093"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Secure Order Management') > -1",
          qThreshold: 999,
          target: "node::2094"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Fixed Networks') > -1",
          qThreshold: 999,
          target: "node::2095"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Rigging') > -1",
          qThreshold: 999,
          target: "node::2096"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – NOC Service Affecting') > -1",
          qThreshold: 999,
          target: "node::2097"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – NOC Non-Service Affecting') > -1",
          qThreshold: 999,
          target: "node::2098"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – NOC KPI Affecting') > -1",
          qThreshold: 999,
          target: "node::2099"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – NOC General') > -1",
          qThreshold: 999,
          target: "node::2100"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('NED – Escalations') > -1",
          qThreshold: 999,
          target: "node::2101"
        }]
      }
    }
  };
});