define("Automatons/automatons/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "automatons",
    attributes: {
      name: "AP Video - Broadband Advert",
      description: "CMRASI-1600",
      initialNode: "node::2004",
      panel: {
        width: 600
      },
      style: "style::2006"
    }
  };
});