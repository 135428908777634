define("Automatons/automatons/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "automatons",
    attributes: {
      name: "AP - Vodafone Pin Code",
      description: "CMRASI-1595",
      initialNode: "node::2002",
      panel: {
        width: 300
      },
      proto: "automaton::2112",
      style: "style::2005"
    }
  };
});