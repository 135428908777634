define("Automatons/automatons/2032", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2032,
    type: "automatons",
    attributes: {
      name: "Vodafone Basic Cons Service Pre-Chat Survey",
      ariaEnabled: true,
      description: "CMRASI-1643",
      initialNode: "node::2109",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});