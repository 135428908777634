define("Automatons/nodes/2327", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2327,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_FDS_Internet&Cloud - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Internet CE Install') > -1",
          qThreshold: 999,
          target: "node::2322"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Internet Customer Activation & Migration') > -1",
          qThreshold: 999,
          target: "node::2323"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Internet Customer Change') > -1",
          qThreshold: 999,
          target: "node::2324"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Internet SNG & Cloud') > -1",
          qThreshold: 999,
          target: "node::2325"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Internet Legacy DIA Test & Install') > -1",
          qThreshold: 999,
          target: "node::2326"
        }]
      }
    }
  };
});