define("Automatons/nodes/2554", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2554,
    type: "nodes",
    attributes: {
      name: "Nanorep Transition - Chat - Copy",
      type: "chat",
      chatRouter: {
        agentGroup: 10007017,
        businessUnit: 19001158
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var nanoRepIntent = typeof api.automatonDataMap.nanoRepIntent !== 'undefined' ? api.automatonDataMap.nanoRepIntent : '';
        var autoOpener = nanoRepIntent ? nanoRepIntent : "Thanks for choosing to chat with us. An agent will be with you shortly";

        if (nanoRepIntent) {
          api.engageChat({
            formData: {
              nanoRepIntent: nanoRepIntent
            },
            autoOpener: autoOpener
          });
        } else {
          api.engageChat({
            autoOpener: autoOpener
          });
        }
      }
    }
  };
});