define("Automatons/automatons/2104", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2104,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_ChannelSteering_V3",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-2827",
      initialNode: "node::2448",
      style: "style::2024"
    }
  };
});