define("Automatons/nodes/2142", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2142,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_VCO_Reactive - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "transcript",
          type: "checkbox",
          checked: "checked",
          inline: 1,
          label: "I want a transcript automatically sent to the email address assigned to my VCO login",
          value: "Yes"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2041-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      onExit: function onExit() {
        var emailAddress = api.automatonDataMap.CUSTOMER_EMAIL;
        //console.log("emailAddress: " + emailAddress);
        //console.log("Email Transcript: " + api.forms['transcript']);
        if (emailAddress && api.forms['transcript'] == "Yes") {
          //console.log("set flag");
          api.fireCustomEvent('SetTranscriptEmail');
          api.emailTranscript(emailAddress);
        }
        if (api.forms['transcript'] == "") {
          //console.log("unset flag");
          api.fireCustomEvent('UnSetTranscriptEmail');
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.automatonDataMap.isVCOTransition === true",
          target: "node::2440"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-111111111'",
          target: "node::2184"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-999999999'",
          target: "node::2185"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-ai'",
          target: "node::2186"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-bbc'",
          target: "node::2187"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-bluelight'",
          target: "node::2188"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-cisco'",
          target: "node::2189"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-computacentre'",
          target: "node::2190"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-contactus21'",
          target: "node::2191"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-corp111111'",
          target: "node::2192"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-corp222222'",
          target: "node::2193"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-corp444444'",
          target: "node::2194"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-council'",
          target: "node::2195"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-dcc'",
          target: "node::2196"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-ge'",
          target: "node::2197"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-government'",
          target: "node::2198"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-health'",
          target: "node::2199"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-kpmg'",
          target: "node::2200"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-l&l'",
          target: "node::2201"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-pool'",
          target: "node::2202"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-regional'",
          target: "node::2203"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-ricoh'",
          target: "node::2204"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-schlumberger'",
          target: "node::2205"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs1'",
          target: "node::2206"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs2'",
          target: "node::2207"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs3'",
          target: "node::2208"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs4'",
          target: "node::2209"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs5'",
          target: "node::2210"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs6'",
          target: "node::2211"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs7'",
          target: "node::2394"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-sse'",
          target: "node::2212"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-thames'",
          target: "node::2213"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge1111111'",
          target: "node::2214"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge2222222'",
          target: "node::2215"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge5555555'",
          target: "node::2216"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge5'",
          target: "node::2216"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge6666666'",
          target: "node::2217"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge7777777'",
          target: "node::2218"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-wpp'",
          target: "node::2219"
        }, {
          condition: "api.automatonDataMap.VCO_REF === 'vco-iot'",
          target: "node::2395"
        }, {
          target: "node::2104"
        }]
      }
    }
  };
});