define("Automatons/nodes/2294", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2294,
    type: "nodes",
    attributes: {
      name: "Vodafone Broadband FAQ PCS - Broadband Activation - Backup Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006369,
        agentsBusyNode: "node::2295",
        businessUnit: 19001161,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.5
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});