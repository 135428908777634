define("Automatons/nodes/2545", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2545,
    type: "nodes",
    attributes: {
      name: "Vodafone OnePotal Pre-Chat Survey - Pub & Gov Other req - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006728,
        agentsBusyNode: "node::2013",
        businessUnit: 19001163,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.2,
        scriptTreeId: 12201250
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        // Fire custom event for setting visitor attributes
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';

        var visitorAttributes = {};

        if (visitorName) {
          visitorAttributes["Name"] = visitorName;
        }
        if (phoneNumber) {
          visitorAttributes["CTN"] = phoneNumber;
        }

        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

        // Include datapass variables in form data if present
        var formData = api.getFormData();

        // Setting customer name to use value in submitted form
        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        // Setting auto opener to nanoRepIntent if it exists, otherwise use default
        var autoOpener = "Hi! How can I help you today?";

        api.engageChat({
          formData: formData,
          autoOpener: autoOpener
        });
      }
    }
  };
});