define("Automatons/nodes/2063", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2063,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Voice - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "email-address",
          type: "text",
          label: "Email Address<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+).([a-zA-Z]{2,5})$",
            patternError: "Please enter a valid e-mail address"
          }
        }, {
          id: "company",
          type: "text",
          label: "Company?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "requested-group",
          type: "select",
          label: "Who do you need to speak to today?<span class='asterisk'>*</span>",
          options: ["Porting First Line Geographic", "Porting First Line Non Geographic", "Porting Second Line"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "help-topic",
          type: "select",
          label: "What do you need help with today?<span class='asterisk'>*</span>",
          options: ["Porting in to Vodafone", "Porting out of Vodafone"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "geographic-or-not",
          type: "select",
          label: "Is your porting query for a Geographic or Non Geographic number?",
          options: ["Geographic", "Non Geographic"],
          placeholder: "Choose one"
        }, {
          id: "reference-number",
          type: "text",
          label: "Do you have a reference number?",
          placeholder: "Type here"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2023-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['requested-group'].indexOf('Porting First Line Geographic') > -1",
          target: "node::2064"
        }, {
          condition: "api.forms['requested-group'].indexOf('Porting First Line Non Geographic') > -1",
          target: "node::2065"
        }, {
          condition: "api.forms['requested-group'].indexOf('Porting Second Line') > -1",
          target: "node::2066"
        }]
      }
    }
  };
});