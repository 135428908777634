define("Automatons/automatons/2132", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2132,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_BusinessSME",
      description: "CMRGEN-22315",
      initialNode: "node::2548",
      proto: "automaton::2099",
      style: "style::2031"
    }
  };
});