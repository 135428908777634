define("Automatons/nodes/2408", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2408,
    type: "nodes",
    attributes: {
      name: "Vodafone Enterprise Service Desk Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "business-sector",
          type: "select",
          label: "Business Sector<span class='asterisk'>*</span>",
          options: ["Non-Dedicated Fixed Desk", "Indirect Partners", "Converged", "Fixed Foundation", "VBSE", "SME Fixed"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[\\+\\s0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "company-name",
          type: "text",
          label: "What company are you contacting us on behalf of?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "reference-number",
          type: "text",
          label: "Reference Number (if known)",
          placeholder: "Type here"
        }, {
          id: "query",
          type: "select",
          dynamic: {
            check: "business-sector",
            value: "Indirect Partners"
          },
          label: "What is the nature of your query?<span class='asterisk'>*</span>",
          options: ["Partners - Fixed Line", "Partners - Unified Comms"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what",
          type: "select",
          dynamic: {
            check: "business-sector",
            logic: "any",
            value: "Converged"
          },
          label: "What is this relating to?<span class='asterisk'>*</span>",
          options: ["Update on a Service Request", "Incident Request"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2096-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }

        var automatonId = api.automaton.id;
        var $viewport = api.automaton.Automaton.View.$viewport;
        var obsTarget = $viewport[0];

        var infoMessage = $('<div style="display: none;"><p>You can check the status of your incident and send/receive updates on <a style="color:#e60000; text-decoration: underline" href="https://login.vodafone.co.uk/login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dvbp%26scope%3Dopenid%2520vf-contact%2520vf-account%2520vf-billing-account%2520vf-subscription%2520vf-profile%2520vf-legacy-session%26response_type%3Dcode%26redirect_uri%3Dhttps%253A%252F%252Fvbp.vodafone.co.uk%252Fauth%252Flogin%252Flogin%252Fcallback%26state%3D%257B%2522successReturnUrl%2522%253A%2522%252F%2522,%2522errorReturnUrl%2522%253A%2522%252F%2522%257D%26acr_values%3DLOA%253A3" target="_blank">Vodafone Business Portal</a> (VBP)</p><p>Your existing OnePortal login details will work for Vodafone Business Portal, or to request access, email our <a style="color:#e60000; text-decoration: underline" href="mailto:vbpdigitaltech@help.vodafone.co.uk">Digital Tech and training Team.</a></p></div>');
        infoMessage.addClass('inq-' + automatonId + '-formify-subheading');

        function createUIElements() {
          var formifyHeader = $('div[acif-template="formify-header"]', $viewport);
          var businessSectorEl = $('select[name="business-sector"]', $viewport);
          formifyHeader.after(infoMessage);
          businessSectorEl.bind('change', function (evt) {
            var selectedValue = $(this).val();
            if (["Non-Dedicated Fixed Desk", "Indirect Partners", "Converged", "Fixed Foundation", "SME Fixed"].indexOf(selectedValue) > -1) {
              infoMessage.show();
            } else {
              infoMessage.hide();
            }
          });
        }

        var observer = new MutationObserver(function (mutations, observer) {
          var inputFieldsCount = $('input', $viewport).length;
          var btnCount = $('button', $viewport).length;
          if (inputFieldsCount > 0 && btnCount > 0) {
            createUIElements();
            observer.disconnect();
          }
        });
        observer.observe(obsTarget, {
          subtree: true,
          childList: true
        });
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['business-sector'].indexOf('Converged') > -1 && api.forms['relating-to-what'].indexOf('Update on a Service Request') > -1",
          target: "node::2463"
        }, {
          condition: "api.forms['business-sector'].indexOf('Converged') > -1 && api.forms['relating-to-what'].indexOf('Incident Request') > -1",
          target: "node::2464"
        }, {
          condition: "api.forms['business-sector'].indexOf('Indirect Partners') > -1 && api.forms['query'].indexOf('Partners - Fixed Line') > -1",
          target: "node::2485"
        }, {
          condition: "api.forms['business-sector'].indexOf('Indirect Partners') > -1 && api.forms['query'].indexOf('Partners - Unified Comms') > -1",
          target: "node::2615"
        }, {
          condition: "api.forms['business-sector'].indexOf('Non-Dedicated Fixed Desk') > -1",
          target: "node::2476"
        }, {
          condition: "api.forms['business-sector'].indexOf('Fixed Foundation') > -1",
          target: "node::2635"
        }, {
          condition: "api.forms['business-sector'].indexOf('VBSE') > -1",
          target: "node::2637"
        }, {
          condition: "api.forms['business-sector'].indexOf('SME Fixed') > -1",
          target: "node::2638"
        }]
      }
    }
  };
});