define("Automatons/nodes/2449", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2449,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_ChannelSteering_V3 - Thank You",
      type: "thankyou",
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        api.setTimeout(function () {
          Inq.closeChat();
        }, 5000);
      },
      outcomeMessage: "User has submitted postchat feedback.",
      template: "template::2041"
    }
  };
});