define("Automatons/nodes/2610", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2610,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Offsite_New - I want to cancel - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006377,
        agentsBusyNode: "node::2473",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2503",
        qThreshold: 999,
        scriptTreeId: 12201409
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});