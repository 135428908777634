define("Automatons/nodes/2067", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2067,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_TES_Test - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "company",
          type: "text",
          label: "Company?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "email-address",
          type: "text",
          label: "Email Address<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+).([a-zA-Z]{2,5})$",
            patternError: "Please enter a valid e-mail address"
          }
        }, {
          id: "enquiry-reason",
          type: "select",
          label: "What is the reason for your enquiry?<span class='asterisk'>*</span>",
          options: ["Alarm Checks", "Bearer Extra (Adva Installation)", "Managed CE", "DSL Router", "BT Bearer Testing (BT Etherflow, FTTC, EFM)", "Other"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "other-enquiry-details",
          type: "text",
          label: "If other please provide details",
          placeholder: "Type here"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2024-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2068"
        }
      }
    }
  };
});