define("Automatons/automatons/2146", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2146,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_Voxi_New",
      ariaEnabled: true,
      description: "CMRGEN-25704",
      initialNode: "node::2590",
      proto: "automaton::2099",
      style: "style::2021"
    }
  };
});