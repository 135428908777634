define("Automatons/nodes/2629", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2629,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Retail_Initial_Credit_Assessment_Survey_Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "credit_status",
          name: "credit_status",
          type: "select",
          label: "What status is the Credit Application in?<span class='asterisk'>*</span>",
          options: ["No Application submitted", "Decline", "Refer"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "no_app_submiited_option",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "No Application submitted"
          },
          label: "<p style='font-size: 0.9rem; font-weight: bold'>Please refer to your BAU process</p>"
        }, {
          id: "decline_option",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "Decline"
          },
          label: "<p style='font-size: 0.9rem; font-weight: bold'>Please see <a href='https://www.vodafone.co.uk/privacy/credit-checks' target='_blank' onclick='Inq.closeChat();' style='text-decoration: underline; font-weight: bold'>Credit checks - Vodafone</a></p>"
        }, {
          id: "query_type",
          type: "select",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "What is the query type?<span class='asterisk'>*</span>",
          options: ["Consumer", "Business"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "dept_name",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "What department are you calling from?<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "name",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "What is your name?<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "ctn_or_ban",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "CTN or BAN?<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "cust_name",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "Customer Name<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "cust_type",
          type: "select",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "Is the customer new or existing?<span class='asterisk'>*</span>",
          options: ["New", "Existing"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "dpa",
          type: "select",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "Have they passed DPA?<span class='asterisk'>*</span>",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome to Credit Assessment",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2062-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2067",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms['credit_status'].indexOf('Refer') > -1 && api.forms['query_type'].indexOf('Consumer') > -1",
          target: "node::2486"
        }, {
          condition: "api.forms['credit_status'].indexOf('Refer') > -1 && api.forms['query_type'].indexOf('Business') > -1",
          target: "node::2487"
        }]
      }
    }
  };
});