define("Automatons/automatons/2059", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2059,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_SOS",
      ariaEnabled: true,
      description: "CMRASI-1893",
      initialNode: "node::2260",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});