define("Automatons/automatons/2160", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2160,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_OneNet-TEST",
      ariaEnabled: true,
      description: "CMRASI-2228",
      initialNode: "node::2630",
      proto: "automaton::2099",
      style: "style::2029"
    }
  };
});