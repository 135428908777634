define("Automatons/nodes/2152", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2152,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessBroadband - 10+ Lines - Contact Us",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2026"
    }
  };
});