define("Automatons/nodes/2088", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2088,
    type: "nodes",
    attributes: {
      name: "Vodafone - Smart Forms - Vulnerable Customers part 2 - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "documents",
          type: "checkbox",
          checked: "",
          inline: 1,
          label: "All Device Plan (loan) documentation has been sent to you, as PDF's, to the email you have provided to us, Please can you confirm you have read and understood these by ticking the box?",
          value: "Yes"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Device Plan Documents",
        next: "submit"
      },
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2087"
        }
      }
    }
  };
});