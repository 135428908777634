define("Automatons/nodes/2117", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2117,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_DES - Expedites - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006448,
        agentsBusyNode: "node::2174",
        businessUnit: 19001170,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});