define("Automatons/automatons/2103", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2103,
    type: "automatons",
    attributes: {
      name: "VOD UK_PostChat_ChannelSteering_MVT",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-2805",
      initialNode: "node::2305",
      style: "style::2024"
    }
  };
});