define("Automatons/nodes/2093", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2093,
    type: "nodes",
    attributes: {
      name: "Vodafone - Smart Forms - Trade in Saving - Initial",
      type: "agent-pushed",
      formify: {
        fields: [{
          id: "code",
          type: "text",
          label: "<span><br/>Please provide me with your 10-digit code which can be generated using the Trade in tool via the My Vodafone App on your device.</span>",
          placeholder: "10-digit code",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]{10}$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "tradein_value_credit_type",
          type: "select",
          label: "Would you like to receive the value for your trade-in device as a one off account credit, A monthly bill credit or a BACS payment?",
          options: ["One off account credit", "A monthly bill credit", "BACS payment"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "tradein_return_method",
          type: "select",
          dynamic: {
            check: "tradein_value_credit_type",
            logic: "any",
            value: "One off account credit;A monthly bill credit;BACS payment"
          },
          label: "Would you like to return your Trade-In device via Store Drop off, Trade-in returns pack or DPD Self-print label?",
          options: ["Store Drop off", "Returns pack", "DPD Self-print label"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "text",
          type: "label",
          dynamic: {
            check: "tradein_return_method",
            logic: "any",
            value: "Store Drop off;Returns pack;DPD Self-print label"
          },
          label: "<span style='font-size:12px;font-weight:normal;color:#666;text-align:left;font-style:italic;line-height:14px;'><p>Please ensure you're 100% happy with your new device before sending your trade-in device to us. If you change your mind after the device has been sent, we will be unable to return the original device. In most cases, you will receive the value of your original device or we will provide a replacement.</p><p>Before sending your trade-in device, you will need to sign out and deactivate any Google, Find My Mobile, Find My iPhone or similar accounts on the device.</p><p>We will cancel your Trade-in order and return your device back to you if the these locks have not been removed.</p></span>"
        }],
        footerText: "",
        heading: "Trade in Saving",
        next: "submit"
      },
      template: "template::2063",
      transitions: {
        submit: [{
          target: "node::2094"
        }]
      }
    }
  };
});