define("Automatons/nodes/2387", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2387,
    type: "nodes",
    attributes: {
      name: "One Number New Customer - Self-Help",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2039"
    }
  };
});