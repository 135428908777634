define("Automatons/nodes/2062", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2062,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_VOXI_Thank_You",
      type: "thankyou",
      isOutcomeNode: 1,
      template: "template::2022"
    }
  };
});