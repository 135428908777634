define("Automatons/nodes/2368", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2368,
    type: "nodes",
    attributes: {
      name: "Vodafone Predictive Care Offsite Engagement Pre Chat Survey - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006494,
        agentsBusyNode: "node::2013",
        businessUnit: 19001159,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1,
        scriptTreeId: 12201267
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});