define("Automatons/nodes/2576", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2576,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessSME Initial - AB Test",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "Hello, what's your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "what-do-you-want-to-talk-about",
          type: "radio",
          label: "What would you like to talk to us about?<span class='asterisk'>*</span>",
          options: [{
            label: "I would like to buy",
            sublabel: "I am not currently a Vodafone customer",
            value: "I would like to buy"
          }, {
            label: "I would like to upgrade",
            sublabel: "I would like to upgrade a service I currently have from Vodafone",
            value: "I would like to upgrade"
          }, {
            label: "Help & Support",
            sublabel: "Account maintenance, billing or device support",
            value: "Help & Support"
          }, {
            label: "Free digital business advice",
            sublabel: "Expert business guidance, resources and support from V-Hub",
            value: "Free digital business advice"
          }],
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-lines",
          type: "select",
          dynamic: {
            check: "what-do-you-want-to-talk-about",
            logic: "any",
            value: "I would like to buy"
          },
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-9", "10-49", "50-249", "250+"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-lines",
          type: "select",
          dynamic: {
            check: "what-do-you-want-to-talk-about",
            logic: "any",
            value: "I would like to upgrade"
          },
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-9", "10-49", "50-249", "250+"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy. For T&C’s for V-Hub click <a href='https://www.vodafone.co.uk/terms-and-conditions/Business/using-v-hub-terms' target='_blank'>here</a>",
        next: "submit"
      },
      initialFocusElement: "#inq-2042-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to buy') > -1 && api.forms['number-of-lines'].indexOf('1-9') > -1",
          target: "node::2145"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to buy') > -1 && api.forms['number-of-lines'].indexOf('10-49') > -1",
          target: "node::2399"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to buy') > -1 && api.forms['number-of-lines'].indexOf('50-249') > -1",
          target: "node::2537"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to buy') > -1 && api.forms['number-of-lines'].indexOf('250+') > -1",
          target: "node::2483"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to upgrade') > -1 && api.forms['number-of-lines'].indexOf('1-9') > -1",
          target: "node::2149"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to upgrade') > -1 && api.forms['number-of-lines'].indexOf('10-49') > -1",
          target: "node::2588"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to upgrade') > -1 && api.forms['number-of-lines'].indexOf('50-249') > -1",
          target: "node::2588"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('I would like to upgrade') > -1 && api.forms['number-of-lines'].indexOf('250+') > -1",
          target: "node::2483"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('Help & Support') > -1",
          target: "node::2577"
        }, {
          condition: "api.forms['what-do-you-want-to-talk-about'].indexOf('Free digital business advice') > -1",
          target: "node::2549"
        }]
      }
    }
  };
});