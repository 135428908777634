define("Automatons/nodes/2298", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2298,
    type: "nodes",
    attributes: {
      name: "Vodafone Proactive Invite - Close Button Clicked",
      type: "exit",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Rejected"
    }
  };
});