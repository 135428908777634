define("Automatons/nodes/2249", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2249,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_Service_Mob_initial_node",
      type: "survey",
      heading: "Thanks for chatting with us",
      inputs: [{
        id: "recommendation-rate",
        type: "select",
        label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction with Vodafone Webchat, how likely are you to recommend Vodafone to your family and friends?<span class='asterisk'>*</span>",
        options: [{
          label: "10 - Extremely likely",
          value: "10"
        }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
          label: "0 - Not at all likely",
          value: "0"
        }],
        placeholder: "Select option",
        required: true
      }, {
        id: "satisfaction-rate",
        type: "select",
        label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, how satisfied are you overall with the service that our team member provided?<span class='asterisk'>*</span>",
        options: [{
          label: "10 - Best",
          value: "10"
        }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
          label: "0 - Worst",
          value: "0"
        }],
        placeholder: "Select option",
        required: true
      }, {
        id: "query-resolved",
        type: "select",
        label: "Has the team member you have been chatting with resolved your query? ( Yes / No )<span class='asterisk'>*</span>",
        options: ["Yes", "No"],
        placeholder: "Select option",
        required: true
      }, {
        id: "transcript-email",
        type: "text",
        label: "Please enter your email address below if you would like to receive a copy of your conversation today. <span style='display: none'>*</span>",
        placeholder: "Email address (optional)",
        value: ""
      }, {
        id: "chat-experience",
        type: "textarea",
        label: "Please tell us a little about your chat experience today",
        placeholder: "Type message..."
      }],
      proto: "node::2405",
      requiredText: "",
      subheading: "",
      template: "template::2029",
      transitions: {
        submit: {
          target: "node::2255"
        }
      }
    }
  };
});