define("Automatons/nodes/2017", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2017,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_Enterpise_initial_node",
      type: "survey",
      formify: {
        fields: [{
          id: "recommendation-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction with Vodafone Webchat, how likely are you to recommend Vodafone to your family and friends?",
          options: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0"],
          placeholder: "Select option"
        }, {
          id: "satisfaction-rate",
          type: "select",
          label: "On a scale of 1-5, where 1 is the worst and 5 is the best, how satisfied are you overall with the service that our team member provided?",
          options: [{
            label: "5 - Best",
            value: "5"
          }, "4", "3", "2", {
            label: "1 - Worst",
            value: "1"
          }],
          placeholder: "Select option"
        }, {
          id: "query-resolved",
          type: "select",
          label: "Has the team member you have been chatting with resolved your query? ( Yes / No )",
          options: ["Yes", "No"],
          placeholder: "Select option"
        }, {
          id: "what-youve-done",
          type: "select",
          label: "What would you have done if you had not received assistance through Vodafone LiveChat?",
          options: ["Sent an eMail", "Made a call", "Done nothing"],
          placeholder: "Select option"
        }, {
          id: "webchat-useful",
          type: "select",
          label: "Did you find webchat useful?",
          options: ["Yes", "No"],
          placeholder: "Select option"
        }, {
          id: "what-you-like",
          type: "textarea",
          label: "What did you like in particular?",
          placeholder: "Type message..."
        }, {
          id: "what-improve-webchat",
          type: "textarea",
          label: "What would you like to see us improve with webchat?",
          placeholder: "Type message..."
        }],
        heading: "Thanks for chatting with us",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      template: "template::2006",
      transitions: {
        submit: {
          target: "node::2018"
        }
      }
    }
  };
});