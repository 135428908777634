define("Automatons/nodes/2118", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2118,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_DealerSupport - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "business-partner-name",
          type: "text",
          label: "Business Partner Name",
          placeholder: "Enter text here"
        }, {
          id: "company-name",
          type: "text",
          label: "Company Name",
          placeholder: "Enter text here"
        }, {
          id: "dealer-code",
          type: "text",
          label: "Dealer Code",
          placeholder: "Enter text here",
          validate: {
            pattern: "^[A-z0-9]*$",
            patternError: "Please enter a valid code"
          }
        }, {
          id: "business-partner-email",
          type: "text",
          label: "Business Partner Email",
          placeholder: "Enter text here"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2036-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          target: "node::2119"
        }]
      }
    }
  };
});