define("Automatons/nodes/2509", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2509,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Model_Office - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name<span class='asterisk'>*</span>",
          placeholder: "Enter text here"
        }, {
          id: "company",
          type: "text",
          label: "What company are you contacting us on behalf of?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field"
        }, {
          id: "reference",
          type: "text",
          label: "Reference Number (if known)",
          placeholder: "Enter text here"
        }, {
          id: "enquiry-chat-reason",
          type: "text",
          label: "What is the nature of your enquiry?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2011-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          target: "node::2510"
        }]
      }
    }
  };
});