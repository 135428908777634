define("Automatons/nodes/2180", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2180,
    type: "nodes",
    attributes: {
      name: "VF_PCS_VCO_Reactive - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-111111111'",
          qThreshold: 1,
          target: "node::2184"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-999999999'",
          qThreshold: 1,
          target: "node::2185"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-ai'",
          qThreshold: 1,
          target: "node::2186"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-bbc'",
          qThreshold: 1,
          target: "node::2187"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-bluelight'",
          qThreshold: 1,
          target: "node::2188"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-cisco'",
          qThreshold: 1,
          target: "node::2189"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-computacentre'",
          qThreshold: 1,
          target: "node::2190"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-contactus21'",
          qThreshold: 1,
          target: "node::2191"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-corp111111'",
          qThreshold: 1,
          target: "node::2192"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-corp222222'",
          qThreshold: 1,
          target: "node::2193"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-corp444444'",
          qThreshold: 1,
          target: "node::2194"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-council'",
          qThreshold: 1,
          target: "node::2195"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-dcc'",
          qThreshold: 1,
          target: "node::2196"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-ge'",
          qThreshold: 1,
          target: "node::2197"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-government'",
          qThreshold: 1,
          target: "node::2198"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-health'",
          qThreshold: 1,
          target: "node::2199"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-kpmg'",
          qThreshold: 1,
          target: "node::2200"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-l&l'",
          qThreshold: 1,
          target: "node::2201"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-pool'",
          qThreshold: 1,
          target: "node::2202"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-regional'",
          qThreshold: 1,
          target: "node::2203"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-ricoh'",
          qThreshold: 1,
          target: "node::2204"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-schlumberger'",
          qThreshold: 1,
          target: "node::2205"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs1'",
          qThreshold: 1,
          target: "node::2206"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs2'",
          qThreshold: 1,
          target: "node::2207"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs3'",
          qThreshold: 1,
          target: "node::2208"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs4'",
          qThreshold: 1,
          target: "node::2209"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs5'",
          qThreshold: 1,
          target: "node::2210"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-scs6'",
          qThreshold: 1,
          target: "node::2211"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-sse'",
          qThreshold: 1,
          target: "node::2212"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-thames'",
          qThreshold: 1,
          target: "node::2213"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge1111111'",
          qThreshold: 1,
          target: "node::2214"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge2222222'",
          qThreshold: 1,
          target: "node::2215"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge5555555'",
          qThreshold: 1,
          target: "node::2216"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge5'",
          qThreshold: 1,
          target: "node::2216"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge6666666'",
          qThreshold: 1,
          target: "node::2217"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-vge7777777'",
          qThreshold: 1,
          target: "node::2218"
        }, {
          AA: true,
          condition: "api.automatonDataMap.VCO_REF === 'vco-wpp'",
          qThreshold: 1,
          target: "node::2219"
        }]
      }
    }
  };
});