define("Automatons/nodes/2427", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2427,
    type: "nodes",
    attributes: {
      name: "Vodafone Black Tariff Pre-Chat Survey - CSV-Cons_BlkService - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006819,
        agentsBusyNode: "node::2341",
        businessUnit: 19001159,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.2
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});