define("Automatons/nodes/2049", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2049,
    type: "nodes",
    attributes: {
      name: "VF_Generic_OHOP",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2019"
    }
  };
});