define("Automatons/automatons/2045", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2045,
    type: "automatons",
    attributes: {
      name: "Nanorep Transition",
      description: "CMRASI-1664",
      initialNode: "node::2222"
    }
  };
});