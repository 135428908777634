define("Automatons/nodes/2558", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2558,
    type: "nodes",
    attributes: {
      name: "Vodafone Broadband Complaints Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "accout-type",
          type: "select",
          label: "What type of account do you have with us?<span class='asterisk'>*</span>",
          options: ["Consumer", "Business"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2027-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['accout-type'].indexOf('Consumer') > -1",
          target: "node::2559"
        }, {
          condition: "api.forms['accout-type'].indexOf('Business') > -1",
          target: "node::2560"
        }]
      }
    }
  };
});