define("Automatons/nodes/2261", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2261,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_SOS - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006433,
        agentsBusyNode: "node::2262",
        businessUnit: 19001168,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999,
        scriptTreeId: 12201270
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});