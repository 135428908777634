define("Automatons/automatons/2137", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2137,
    type: "automatons",
    attributes: {
      name: "Vodafone Broadband Complaints Pre-Chat Survey",
      ariaEnabled: true,
      description: "CMRGEN-24320",
      initialNode: "node::2558",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});