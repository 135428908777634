define("Automatons/nodes/2421", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2421,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Autoswitching_LoggedIn - Small Business - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006489,
        agentsBusyNode: "node::2048",
        businessUnit: 19001163,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.2
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});