define("Automatons/nodes/2397", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2397,
    type: "nodes",
    attributes: {
      name: "Vodafone - File Upload - Initial",
      type: "agent-pushed",
      fileUpload: {},
      template: "template::2040",
      transitions: {
        fileUpload: [{
          condition: "api.fileUploadStatus === 'success'",
          target: "node::2398"
        }]
      }
    }
  };
});