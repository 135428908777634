define("Automatons/nodes/2136", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2136,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_ChannelPartners - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "dealer-password",
          type: "text",
          label: "Dealer Password?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "passed-dpa",
          type: "select",
          label: "DPA passed?<span class='asterisk'>*</span>",
          options: ["Yes", "No"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "branch-code",
          type: "text",
          label: "Branch code?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "chat-reason",
          type: "select",
          label: "Reason for chat?<span class='asterisk'>*</span>",
          options: ["Contingency Connection", "SIM Change", "Incorrect Price Plan", "Billing Query", "Upgrade Eligibility Check", "Legacy Connection", "Other (See Summary)"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-summary",
          type: "textarea",
          label: "Summary of query?",
          placeholder: "Type here"
        }, {
          id: "customer-name",
          type: "text",
          label: "Customer name?",
          placeholder: "Type here"
        }, {
          id: "customer-mobile-num",
          type: "text",
          label: "Customer Mobile No?",
          placeholder: "Type here"
        }, {
          id: "customer-postcode",
          type: "text",
          label: "Customer Postcode?",
          placeholder: "Type here"
        }, {
          id: "customer-dob",
          type: "text",
          label: "Customer Date of Birth?",
          placeholder: "Type here"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2039-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "true",
          target: "node::2137"
        }]
      }
    }
  };
});