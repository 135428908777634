define("Automatons/nodes/2431", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2431,
    type: "nodes",
    attributes: {
      name: "Vodafone Generic Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field."
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?",
          placeholder: "Enter text here"
        }, {
          id: "chat-reason",
          type: "select",
          hidden: true,
          label: "Chat Reason",
          options: [{
            label: "Offiste Default",
            selected: true,
            value: "Offsite Default"
          }]
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2062-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
        if (api.automatonDataMap.hasOwnProperty('chatReason')) {
          this.formify.fields.forEach(function (field) {
            if (field.id == "chat-reason") {
              field.options[0].label = api.automatonDataMap.chatReason;
              field.options[0].value = api.automatonDataMap.chatReason;
            }
          });
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2432"
        }
      }
    }
  };
});