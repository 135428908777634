define("Automatons/nodes/2625", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2625,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_VBPSupport Survey - VBP/OnePortal Faults - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006820,
        agentsBusyNode: "node::2013",
        businessUnit: 19001163,
        checkAgentAvailability: true,
        outsideHopNode: "node::2602",
        qThreshold: 1.2,
        scriptTreeId: 12201250
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        // Fire custom event for setting visitor attributes
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';
        var companyName = typeof api.forms['company-name'] !== 'undefined' ? api.forms['company-name'] : '';

        var visitorAttributes = {};

        if (visitorName) {
          visitorAttributes["Name"] = visitorName;
        }
        if (phoneNumber) {
          visitorAttributes["CTN"] = phoneNumber;
        }
        if (companyName) {
          visitorAttributes["Company_Name"] = companyName;
        }

        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

        // Include datapass variables in form data if present
        var formData = api.getFormData();

        // Setting customer name to use value in submitted form
        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        // Setting auto opener to nanoRepIntent if it exists, otherwise use default
        var autoOpener = "If you require a copy of this chat, you can click the envelope icon at the bottom of the chat and enter your email address to allow us to send you a copy of this transcript. If you require an unedited copy, please copy and paste from the chat box prior to the chat session ending. Once the chat session has ended you will not be able to retrieve a copy of the transcript.<br><br>Thanks for choosing to chat with us, an agent will be with you shortly.";

        api.engageChat({
          formData: formData,
          autoOpener: autoOpener
        });
      }
    }
  };
});