define("Automatons/nodes/2469", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2469,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_SUI_Offsite - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: "",
        agentsBusyNode: "node::2473",
        businessUnit: "",
        checkAgentAvailability: true,
        outsideHopNode: "node::2503",
        qThreshold: 999,
        scriptTree: 12201267
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});