define("Automatons/nodes/2359", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2359,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_OneNet - One Net Business TAP's Team - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006707,
        agentsBusyNode: "node::2362",
        businessUnit: 19001166,
        checkAgentAvailability: true,
        outsideHopNode: "node::2602",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});