define("Automatons/nodes/2061", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2061,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_VOXI_initial_node",
      type: "survey",
      formify: {
        fields: [{
          id: "recommendation-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction with VOXI Webchat, how likely are you to recommend VOXI to your family and friends?",
          options: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0"],
          placeholder: "Select option"
        }, {
          id: "satisfaction-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, how satisfied are you overall with the service that our team member provided?",
          options: [{
            label: "10 - Best",
            value: "10"
          }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
            label: "0 - Worst",
            value: "0"
          }],
          placeholder: "Select option"
        }, {
          id: "query-resolved",
          type: "select",
          label: "Has the team member you have been chatting with resolved your query? ( Yes / No )",
          options: ["Yes", "No"],
          placeholder: "Select option"
        }, {
          id: "feedback",
          type: "textarea",
          label: "Please tell us a little about your chat experience today",
          placeholder: "Type message..."
        }],
        heading: "Thanks for chatting with us",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      template: "template::2006",
      transitions: {
        submit: {
          target: "node::2062"
        }
      }
    }
  };
});