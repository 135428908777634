define("Automatons/automatons/2010", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2010,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_Enterprise",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-1604",
      initialNode: "node::2017",
      style: "style::2016"
    }
  };
});