define("Automatons/automatons/2097", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2097,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_Autoswitching_LoggedIn",
      ariaEnabled: true,
      description: "CMRASI-2525",
      initialNode: "node::2418",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});