define("Automatons/nodes/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "nodes",
    attributes: {
      name: "AP - Vodafone Pin Code Initial",
      type: "agent-pushed",
      actions: {
        sendFormDataToAgent: {
          action: "sendFormDataToAgent"
        }
      },
      onEntryTransition: "checkIfAllowed",
      template: "template::2002",
      transitions: {
        checkIfAllowed: {
          condition: "api.get('showSecureForm') === false",
          target: "node::2471"
        }
      }
    }
  };
});