define("Automatons/nodes/2309", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2309,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_FDS_Ethernet_and_Test - E&T_DSLIntegration - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006598,
        agentsBusyNode: "node::2313",
        businessUnit: 19001170,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});