define("Automatons/automatons/2093", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2093,
    type: "automatons",
    attributes: {
      name: "Vodafone Black Tariff Pre-Chat Survey",
      ariaEnabled: true,
      description: "CMRASI-2661",
      initialNode: "node::2425",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});