define("Automatons/nodes/2462", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2462,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Retail_OneKnowledgeSupport - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "store-code",
          type: "text",
          label: "What is your store code?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please use numbers only"
          }
        }, {
          id: "oneknowledge-passcode",
          type: "text",
          label: "OneKnowledge Passcode?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]{6}$",
            patternError: "Please enter the 6 digits passcode only"
          }
        }, {
          id: "ctn-or-ban",
          type: "text",
          label: "CTN or BAN?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please use numbers only"
          }
        }, {
          id: "customer-name",
          type: "text",
          label: "Customer Name<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "passed-dpa",
          type: "select",
          label: "Have they passed DPA?<span class='asterisk'>*</span>",
          options: ["Yes", "Not Required"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-type",
          type: "select",
          label: "Query Type<span class='asterisk'>*</span>",
          options: ["Order Verification", "ID Verification", "Address", "Credit Vet", "Order Pricing Issue", "Create/Recreate Order", "Services", "Other"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-order-verif",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Order Verification"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Upgrade", "PAYG", "Pre to post", "Flexi Upgrade", "One Number", "Return", "Exchange", "Transfer of Ownership", "Tariff migration", "SIM swap", "Add/Remove SOC", "Discounts", "HBB", "MBB", "DTS/CIS", "Insurance", "Red+ Leader/Sharer", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-id-verif",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "ID Verification"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Pre to post", "Transfer of Ownership"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-address",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Address"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Pre to post", "Upgrade", "Flexi Upgrade", "Credit Vet", "Transfer of Ownership", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-credit-vet",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Credit Vet"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Upgrade", "Pre to post", "Return", "Exchange", "Transfer of Ownership", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-order-pricing-issue",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Order Pricing Issue"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["New Connection", "Additional line", "Upgrade", "Flexi Upgrade", "Pre to post", "Return", "Exchange", "PAYG", "Early Upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-create-order",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Create/Recreate Order"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["Open order", "Pending order", "Cancelled order", "Blank Modify promo", "Handset / Device Band", "Upfront cost", "Monthly cost"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-services",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Services"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["Service hierarchy- Merge", "Service hierarchy- Split", "SIM Swap", "Update IMEI", "Check IMEI", "Blacklist", "Billing", "Online account", "Credits", "Price plan compatibility", "ETF/EUF check", "Upgrade Eligibility", "Commitment Date", "Disconnection/Reconnection", "Discounts", "Call Validate", "Add authorised contact", "Check order status"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-sub-type-other",
          type: "select",
          dynamic: {
            check: "query-type",
            value: "Other"
          },
          label: "Query Sub-Type<span class='asterisk'>*</span>",
          options: ["Data not working", "Entertainment pack", "Digital signature", "Jill/Till issues"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "issue-summary",
          type: "textarea",
          label: "Summary of the issue<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2038-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2470"
        }
      }
    }
  };
});