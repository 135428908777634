define("Automatons/nodes/2082", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2082,
    type: "nodes",
    attributes: {
      name: "Vodafone Complaints Pre-Chat Survey - Anything else Pay Monthly - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006884,
        agentsBusyNode: "node::2013",
        businessUnit: 19001159,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});