define("Automatons/nodes/2094", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2094,
    type: "nodes",
    attributes: {
      name: "Vodafone - Smart Forms - Trade in Saving - Exit",
      type: "exit",
      isExitNode: 1,
      isOutcomeNode: 1,
      onExit: function onExit() {
        var formData = api.getAllFormData();
        var systemDatapass = toDatapassString(formData);

        function toDatapassString(formDataObj) {
          var datapassArray = [];
          for (var question in formDataObj) {
            if (formDataObj.hasOwnProperty(question) && formDataObj[question] != '') {
              datapassArray.push(question + ' - ' + formDataObj[question]);
            }
          }
          return datapassArray.join('<br/>');
        }
        api.IJSF.sendSystemMessage(systemDatapass, "successful");
        api.sendFormDataToAgent();

        var map = JSON.stringify({
          "response": api.forms['documents']
        });
        try {
          window.setAutomatonDataMap(map);
          console.log(map);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };
});