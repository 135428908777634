define("Automatons/nodes/2483", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2483,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessMobile - 50+ Lines - Contact Us",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2048"
    }
  };
});