define("Automatons/automatons/2021", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2021,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_VPS",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-1606",
      initialNode: "node::2059",
      style: "style::2018"
    }
  };
});