define("Automatons/nodes/2161", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2161,
    type: "nodes",
    attributes: {
      name: "Broadband PCS - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I need help with my home broadband') > -1",
          qThreshold: 1.5,
          target: "node::2035"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Broadband Activation') > -1",
          qThreshold: 1.5,
          target: "node::2289"
        }]
      }
    }
  };
});