define("Automatons/nodes/2211", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2211,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_VCO_Reactive - ESC-VCO_SCS6 - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006413,
        agentsBusyNode: "node::2104",
        businessUnit: 19001163,
        outsideHopNode: "node::2049"
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});