define("Automatons/nodes/2090", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2086,
    type: "nodes",
    attributes: {
      name: "Vodafone - Smart Forms - Vulnerable Customers part 1 - Vulnerabilities",
      type: "survey",
      formify: {
        fields: [{
          id: "consent",
          type: "select",
          label: "Thank you for sharing your details, these will be processed and stored in a secure manner in accordance with our privacy policy which can be found at www.vodafone.co.uk/privacy</p><p>Do you consent for me to update your account with this information to ensure you don't need to explain your circumstances again next time you contact us?",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        next: "submit"
      },
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        //if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
        //api.resize(300, 490);
        //}
      },
      template: "template::2063",
      transitions: {
        submit: {
          target: "node::2087"
        }
      }
    }
  };
});