define("Automatons/automatons/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "automatons",
    attributes: {
      name: "AP Form - Dynamic Form - Rows",
      description: "CMRASI-1614",
      initialNode: "node::2005",
      panel: {
        height: 300
      },
      style: "style::2010"
    }
  };
});