define("Automatons/nodes/2029", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2029,
    type: "nodes",
    attributes: {
      name: "Vodafone Busy Service",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2017"
    }
  };
});