define("Automatons/nodes/2156", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2156,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_VONEC - ESV-VoneC_Service - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006382,
        agentsBusyNode: "node::2104",
        businessUnit: 19001163,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});