define("Automatons/nodes/2383", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2383,
    type: "nodes",
    attributes: {
      name: "Vodafone Broadband PCS - Join Vodafone - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006917,
        agentsBusyNode: "node::2475",
        businessUnit: 19001162,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.5,
        scriptTreeId: 12201263
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});