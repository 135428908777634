define("Automatons/nodes/2617", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2617,
    type: "nodes",
    attributes: {
      name: "Small Business - Service Routing Blocking Node",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2064"
    }
  };
});