define("Automatons/nodes/2019", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2019,
    type: "nodes",
    attributes: {
      name: "Vodafone Agents Busy - Support Redirect",
      type: "redirect",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Redirected to support.vodafone.co.uk",
      redirectTarget: "current",
      redirectUrl: "https://support.vodafone.co.uk"
    }
  };
});