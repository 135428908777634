define("Automatons/nodes/2048", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2048,
    type: "nodes",
    attributes: {
      name: "VF_Generic_Busy",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2018"
    }
  };
});