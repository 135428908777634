define("Automatons/nodes/2534", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2534,
    type: "nodes",
    attributes: {
      name: "VF_Name_EmailOnly_INTERNAL-Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10007035,
        agentsBusyNode: "node::2055",
        businessUnit: 19001261,
        checkAgentAvailability: true,
        outsideHopNode: "node::2013",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});