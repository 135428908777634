define("Automatons/automatons/2112", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2112,
    type: "automatons",
    attributes: {
      name: "AP - Secure Form Prototype",
      onPageLanding: function onPageLanding() {
        var allowedAGs = api.top.inqFrame.Inq.PM.getVar("SecureFormsAllowedAGs") ? api.top.inqFrame.Inq.PM.getVar("SecureFormsAllowedAGs").z0() : "";
        var currentAG = api.top.Inq.getData() ? api.top.Inq.getData().CHM.agID : "";
        var isCurrentAGAllowed = allowedAGs.includes(currentAG);
        var allowAll = allowedAGs === "all" ? true : false;

        if (isCurrentAGAllowed && Boolean(allowedAGs) && Boolean(currentAG) || allowAll) {
          api.save("showSecureForm", true);
        } else {
          api.save("showSecureForm", false);
          api.IJSF.sendDatapassMessage("WARNING! Secure Forms have been disabled for now. The customer will not see it. Please do not try again. If unsure, contact your supervisor.");
        }
      }
    }
  };
});