define("Automatons/nodes/2115", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2115,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_DES - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "email-address",
          type: "text",
          label: "Email Address<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+).([a-zA-Z]{2,5})$",
            patternError: "Please enter a valid e-mail address"
          }
        }, {
          id: "requested-group",
          type: "select",
          label: "Who do you need to speak to today?<span class='asterisk'>*</span>",
          options: ["Escalations", "Expedites"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "query-reason",
          type: "select",
          label: "What do you need help with today?<span class='asterisk'>*</span>",
          options: ["I need an update", "I need to update Vodafone", "I want to complain", "I want to raise an order"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2035-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['requested-group'].indexOf('Escalations') > -1",
          target: "node::2116"
        }, {
          condition: "api.forms['requested-group'].indexOf('Expedites') > -1",
          target: "node::2117"
        }]
      }
    }
  };
});