define("Automatons/nodes/2243", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2243,
    type: "nodes",
    attributes: {
      name: "Vodafone_AppleWatch_Widget_Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field"
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?",
          placeholder: "Enter text here"
        }, {
          id: "chat-reason",
          type: "select",
          label: "What would you like to chat to us about?<span class='asterisk'>*</span>",
          options: ["I want to purchase the Apple Watch", "I want to purchase the latest iPhone", "Anything else"],
          placeholder: "Choose item from the list",
          required: true,
          requiredError: "This is a required field"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2053-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.automatonDataMap.isAppleWatchDevelopment === true && api.forms['chat-reason'].indexOf('I want to purchase the Apple Watch') > -1",
          target: "node::2266"
        }, {
          condition: "api.automatonDataMap.isAppleWatchDevelopment === true && api.forms['chat-reason'].indexOf('I want to purchase the latest iPhone') > -1",
          target: "node::2266"
        }, {
          condition: "api.automatonDataMap.isAppleWatchDevelopment === true && api.forms['chat-reason'].indexOf('Anything else') > -1",
          target: "node::2267"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I want to purchase the Apple Watch') > -1",
          target: "node::2244"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I want to purchase the latest iPhone') > -1",
          target: "node::2245"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Anything else') > -1",
          target: "node::2246"
        }]
      }
    }
  };
});