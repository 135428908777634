define("Automatons/nodes/2351", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2351,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_SME - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters only"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Mobile Phone Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9 ]*$",
            patternError: "Please use numbers only"
          }
        }, {
          id: "account-number",
          type: "text",
          label: "Account Number",
          placeholder: "Type here"
        }, {
          id: "email-address",
          type: "text",
          label: "Email Address<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "chat-reason",
          type: "select",
          label: "What would you like to chat to us about today?<span class='asterisk'>*</span>",
          options: ["New Customer", "Service"],
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      initialFocusElement: "#inq-2077-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['chat-reason'].indexOf('New Customer') > -1",
          target: "node::2352"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Service') > -1",
          target: "node::2353"
        }]
      }
    }
  };
});