define("Automatons/nodes/2424", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2424,
    type: "nodes",
    attributes: {
      name: "Vodafone Together Pre-Chat Survey - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I would like to join Vodafone') > -1",
          qThreshold: 1.2,
          target: "node::2403"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I need help with my phone, bill or account') > -1",
          qThreshold: 1.2,
          target: "node::2404"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I need help with my broadband') > -1",
          qThreshold: 1.2,
          target: "node::2422"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Broadband Activations') > -1",
          qThreshold: 1.2,
          target: "node::2423"
        }]
      }
    }
  };
});