define("Automatons/automatons/2042", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2042,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_BusinessMobile",
      ariaEnabled: true,
      description: "CMRASI-1655",
      initialNode: "node::2144",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});