define("Automatons/nodes/2602", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2602,
    type: "nodes",
    attributes: {
      name: "VF_Enterprise_OHOP",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2061"
    }
  };
});