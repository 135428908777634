define("Automatons/automatons/2159", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2159,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_Retail_Initial_Credit_Assessment_Survey",
      ariaEnabled: true,
      description: "CMRGEN-29782",
      initialNode: "node::2629",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});