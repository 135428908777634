define("Automatons/automatons/2108", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2108,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_RegionalCollections",
      type: "guide",
      ariaEnabled: true,
      description: "CMRASI-2905",
      initialNode: "node::2460",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});