define("Automatons/nodes/2245", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2245,
    type: "nodes",
    attributes: {
      name: "Vodafone_AppleWatch_Widget_Survey - iPhone - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006377,
        agentsBusyNode: "node::2247",
        businessUnit: 19001162,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.2,
        scriptTreeId: 12201267
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';

        api.fireCustomEvent('UpdateVisitorAttribute', {
          Name: visitorName,
          CTN: phoneNumber
        });

        var formData = api.getFormData();
        var chatReason = typeof api.forms['chat-reason'] !== 'undefined' ? api.forms['chat-reason'] : '';
        var autoOpener = chatReason ? chatReason : "Thanks for choosing to chat with us. An agent will be with you shortly.";
        var nanoRepIntent = chatReason;

        api.fireCustomEvent('AppleNanoRepIntent', {
          nanoRepIntent: nanoRepIntent
        });

        if (nanoRepIntent) {
          formData = formData + '\n- nanoRepIntent: ' + nanoRepIntent;
        }

        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        api.engageChat({
          formData: formData,
          autoOpener: autoOpener
        });
      }
    }
  };
});