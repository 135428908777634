define("Automatons/nodes/2521", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2521,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_TranscriptOnlyWhite - Thank You",
      type: "thankyou",
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var customerEmail = typeof api.forms['transcript-email'] !== 'undefined' ? api.forms['transcript-email'] : '';

        if (customerEmail) {
          api.emailTranscript(customerEmail);
          //console.log("email sent to " + customerEmail);
        }
      },
      template: "template::2036"
    }
  };
});