define("Automatons/automatons/2027", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2027,
    type: "automatons",
    attributes: {
      name: "Vodafone Broadband Complaints Pre-Chat Survey",
      ariaEnabled: true,
      description: "CMRGEN-24183",
      initialNode: "node::2073",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});