define("Automatons/nodes/2144", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2144,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessMobile - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "chat-reason",
          type: "select",
          label: "What would you like to talk to us about?<span class='asterisk'>*</span>",
          options: ["I'd like to buy from Vodafone", "I need help with my Phone, Bill, or Account", "I want to upgrade"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-lines",
          type: "select",
          dynamic: {
            check: "chat-reason",
            logic: "any",
            value: "I'd like to buy from Vodafone"
          },
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-9", "10-49", "50-249", "250+"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "number-of-lines",
          type: "select",
          dynamic: {
            check: "chat-reason",
            logic: "any",
            value: "I want to upgrade"
          },
          label: "How many employees does your business have?<span class='asterisk'>*</span>",
          options: ["1-9", "10-49", "50-249", "250+"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2042-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }

        var salesNode = this.Automaton.getRecord("node::2148");
        var brName = api.flashVars.brName;
        var businessRules = "VOD-B-LC-SalesNew-O-SMB-51-250-DT-Fixed|VOD-B-LC-SalesNew-O-SMB-51-250-S-Fixed|VOD-B-LC-SalesNew-O-SMB-10-50-DT-Fixed|VOD-B-LC-SalesNew-O-SMB-10-50-S-Fixed";
        var regEx = new RegExp(businessRules, "gi");

        if (brName.match(regEx) != null) {
          salesNode.chatRouter.outsideHopNode = "node::2603";
          salesNode.chatRouter.businessUnit = "node::2603";
          salesNode.chatRouter.agentGroup = "node::2603";
          salesNode.chatRouter.agentsBusyNode = "node::2603";
          //console.log("new HoP");
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms[\'chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'1-9\') > -1",
          target: "node::2145"
        }, {
          condition: "api.forms[\'chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'10-49\') > -1",
          target: "node::2399"
        }, {
          condition: "api.forms[\'chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'250+\') > -1",
          target: "node::2483"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I need help with my Phone, Bill, or Account') > -1",
          target: "node::2617"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('1-9') > -1",
          target: "node::2149"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('10-49') > -1",
          target: "node::2588"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('250+') > -1",
          target: "node::2483"
        }, {
          condition: "api.forms[\'chat-reason\'].indexOf(\'I\\\'d like to buy from Vodafone\') > -1 && api.forms[\'number-of-lines\'].indexOf(\'50-249\') > -1",
          target: "node::2537"
        }, {
          condition: "api.forms['chat-reason'].indexOf('I want to upgrade') > -1 && api.forms['number-of-lines'].indexOf('50-249') > -1",
          target: "node::2588"
        }]
      }
    }
  };
});