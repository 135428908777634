define("Automatons/nodes/2035", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2035,
    type: "nodes",
    attributes: {
      name: "Vodafone Broadband PCS - Help With Home Broadband - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006334,
        agentsBusyNode: "node::2161",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.5,
        scriptTreeId: 12201267
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {

        api.fireCustomEvent('TobiUpgradeIntent', {
          TobiUpgradeIntent: "#Router-Greetings"
        });

        var nanoRepIntent = typeof api.automatonDataMap.nanoRepIntent !== 'undefined' ? api.automatonDataMap.nanoRepIntent : '';
        //    var autoOpener = nanoRepIntent ? nanoRepIntent : "Thanks for choosing to chat with us. An agent will be with you shortly";

        if (nanoRepIntent) {
          api.engageChat({
            formData: {
              nanoRepIntent: nanoRepIntent
            },
            autoOpener: autoOpener
          });
        } else {
          api.engageChat({
            autoOpener: autoOpener
          });
        }
      },
      proto: "node::2183"
    }
  };
});