define("Automatons/automatons/2054", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2054,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_Service_Mob",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-1872, CMRASI-2489",
      initialNode: "node::2249",
      style: "style::2022"
    }
  };
});