define("Automatons/nodes/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "nodes",
    attributes: {
      name: "Exit",
      type: "exit",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Exited from ACIF automaton."
    }
  };
});