define("Automatons/nodes/2255", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2255,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_Service_Mob_Thank_You",
      type: "thankyou",
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var customerEmail = typeof api.forms['transcript-email'] !== 'undefined' ? api.forms['transcript-email'] : '';

        function isValidEmail(email) {
          var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

          if (reg.test(email) == false) {
            return false;
          }
          return true;
        }

        if (isValidEmail(customerEmail)) {
          api.emailTranscript(customerEmail);
          //console.log("email sent to " + customerEmail);
        }
      },
      template: "template::2030"
    }
  };
});