define("Automatons/automatons/2134", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2134,
    type: "automatons",
    attributes: {
      name: "VOD UK_PostChat_IT",
      description: "CMRGEN-22348",
      initialNode: "node::2553",
      style: "style::2015"
    }
  };
});