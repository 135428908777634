define("Automatons/nodes/2313", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2313,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_FDS_Ethernet_and_Test - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Bearer Test and Integration') > -1",
          qThreshold: 999,
          target: "node::2308"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('DSL Integration and Migration') > -1",
          qThreshold: 999,
          target: "node::2309"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('EWL, EVPN, SDH, DSL Pre Build') > -1",
          qThreshold: 999,
          target: "node::2310"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('PreBuild Inflight Change') > -1",
          qThreshold: 999,
          target: "node::2311"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('Build Projects') > -1",
          qThreshold: 999,
          target: "node::2312"
        }]
      }
    }
  };
});