define("Automatons/automatons/2020", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2020,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_Sales",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-1605",
      initialNode: "node::2057",
      style: "style::2017"
    }
  };
});