define("Automatons/automatons/2161", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2161,
    type: "automatons",
    attributes: {
      name: "VF_Blocking_Node",
      ariaEnabled: true,
      description: "CMRGEN-30156",
      initialNode: "node::2634",
      proto: "automaton::2099",
      style: "style::2029"
    }
  };
});