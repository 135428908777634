define("Automatons/nodes/2611", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2611,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Offsite_New - buy Home Broadband - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006376,
        agentsBusyNode: "node::2473",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2503",
        qThreshold: 999,
        scriptTreeId: 12201411
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});