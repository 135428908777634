define("Automatons/nodes/2520", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2520,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_TranscriptOnlyWhite - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "transcript-email",
          type: "text",
          label: "Please enter your email address below if you would like to receive a copy of your conversation today or leave it blank if you would not like one.",
          placeholder: "Email address",
          validate: {
            pattern: "^$|^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,65})$",
            patternError: "Please enter a valid e-mail address"
          },
          value: ""
        }],
        heading: "Thanks for chatting with us",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      template: "template::2006",
      transitions: {
        submit: {
          target: "node::2521"
        }
      }
    }
  };
});