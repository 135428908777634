define("Automatons/nodes/2452", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2452,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Complaints - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field.",
          validate: {
            pattern: "^[a-zA-Z0-9- ']*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is a required field.",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "complaint-status",
          type: "select",
          label: "New or Existing Complaint?",
          options: ["New Complaint", "Existing Complaint"]
        }, {
          id: "complaint-topic",
          type: "select",
          dynamic: {
            check: "complaint-status",
            value: "New Complaint"
          },
          label: "Is you new complaint relating to:",
          options: ["Mobile - Pay as you go", "Mobile - Pay monthly", "Home Broadband", "Mobile Broadband", "Internet of Things", "Other"]
        }, {
          id: "complaint-reason",
          type: "textarea",
          dynamic: {
            check: "complaint-topic",
            logic: "any",
            value: "Mobile - Pay as you go;Mobile - Pay monthly;Mobile Broadband;Other"
          },
          label: "Reason for complaint",
          validate: {
            maxlength: 400
          }
        }, {
          id: "complaint-case-id",
          type: "text",
          dynamic: {
            check: "complaint-status",
            value: "Existing Complaint"
          },
          label: "Please enter your Case ID"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2107-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['complaint-topic'].indexOf('Home Broadband') > -1",
          target: "node::2552"
        }, {
          condition: "api.forms['complaint-topic'].indexOf('Internet of Things') > -1",
          target: "node::2459"
        }, {
          target: "node::2454"
        }]
      }
    }
  };
});