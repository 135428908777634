define("Automatons/nodes/2108", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2108,
    type: "nodes",
    attributes: {
      name: "Vodafone Basic Upgrades Pre-Chat Survey - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006377,
        agentsBusyNode: "node::2173",
        businessUnit: 19001162,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1,
        scriptTreeId: 12201273
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});