define("Automatons/nodes/2167", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2167,
    type: "nodes",
    attributes: {
      name: "VF_PCS_Voice - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Porting First Line Geographic') > -1",
          qThreshold: 999,
          target: "node::2064"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Porting First Line Non Geographic') > -1",
          qThreshold: 999,
          target: "node::2065"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Porting Second Line') > -1",
          qThreshold: 999,
          target: "node::2066"
        }]
      }
    }
  };
});