define("Automatons/nodes/2143", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2143,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_VCO_Reactive - Chat - Unused",
      type: "chat",
      chatRouter: {
        agentsBusyNode: "node::2180",
        businessUnit: 19001163,
        checkAgentAvailability: true,
        clientOutcome: "Thanks for choosing to chat with us. An agent will be with you shortly",
        outsideHopNode: "node::2049",
        qThreshold: 1
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        if (api.automatonDataMap.VCO_REF == "VCO-111111111") {
          api.engageChat({
            agentGroup: 10006386
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-999999999") {
          api.engageChat({
            agentGroup: 10006387
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-AI") {
          api.engageChat({
            agentGroup: 10006388
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-BBC") {
          api.engageChat({
            agentGroup: 10006389
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-bluelight") {
          api.engageChat({
            agentGroup: 10006390
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Cisco") {
          api.engageChat({
            agentGroup: 10006391
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-computacentre") {
          api.engageChat({
            agentGroup: 10006392
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-contactus21") {
          api.engageChat({
            agentGroup: 10006393
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Corp111111") {
          api.engageChat({
            agentGroup: 10006394
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Corp222222") {
          api.engageChat({
            agentGroup: 10006395
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Corp444444") {
          api.engageChat({
            agentGroup: 10006396
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-council") {
          api.engageChat({
            agentGroup: 10006397
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-DCC") {
          api.engageChat({
            agentGroup: 10006398
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-GE") {
          api.engageChat({
            agentGroup: 10006399
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-government") {
          api.engageChat({
            agentGroup: 10006400
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-health") {
          api.engageChat({
            agentGroup: 10006401
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-KPMG") {
          api.engageChat({
            agentGroup: 10006402
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-L&L") {
          api.engageChat({
            agentGroup: 10006403
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Pool") {
          api.engageChat({
            agentGroup: 10006404
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-regional") {
          api.engageChat({
            agentGroup: 10006405
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Ricoh") {
          api.engageChat({
            agentGroup: 10006406
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Schlumberger") {
          api.engageChat({
            agentGroup: 10006407
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-SCS1") {
          api.engageChat({
            agentGroup: 10006408
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-SCS2") {
          api.engageChat({
            agentGroup: 10006409
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-SCS3") {
          api.engageChat({
            agentGroup: 10006410
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-SCS4") {
          api.engageChat({
            agentGroup: 10006411
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-SCS5") {
          api.engageChat({
            agentGroup: 10006412
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-SCS6") {
          api.engageChat({
            agentGroup: 10006413
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-SSE") {
          api.engageChat({
            agentGroup: 10006414
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-Thames") {
          api.engageChat({
            agentGroup: 10006415
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-VGE1111111") {
          api.engageChat({
            agentGroup: 10006416
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-VGE2222222") {
          api.engageChat({
            agentGroup: 10006417
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-VGE5555555") {
          api.engageChat({
            agentGroup: 10006418
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-VGE6666666") {
          api.engageChat({
            agentGroup: 10006419
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-VGE7777777") {
          api.engageChat({
            agentGroup: 10006420
          });
        } else if (api.automatonDataMap.VCO_REF == "VCO-WPP") {
          api.engageChat({
            agentGroup: 10006421
          });
        } else {
          api.engageChat({
            agentGroup: 10006385
          });
        }
      }
    }
  };
});