define("Automatons/nodes/2561", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2561,
    type: "nodes",
    attributes: {
      name: "Vodafone Complaints Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "What is your phone number?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "chat-reason",
          type: "select",
          label: "What is your complaint regarding?<span class='asterisk'>*</span>",
          options: ["Network", "Billing", "Broadband", "Internet of Things", "Anything else"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what",
          type: "select",
          dynamic: {
            check: "chat-reason",
            logic: "any",
            value: "Network;Billing"
          },
          label: "What type of account do you have with us?<span class='asterisk'>*</span>",
          options: ["Pay Monthly", "Pay as you go", "Small Medium Business", "Broadband"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what1",
          type: "select",
          dynamic: {
            check: "chat-reason",
            logic: "any",
            value: "Broadband"
          },
          label: "What type of account do you have with us?<span class='asterisk'>*</span>",
          options: ["Consumer", "Business"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what2",
          type: "select",
          dynamic: {
            check: "chat-reason",
            logic: "any",
            value: "Anything else"
          },
          label: "What type of account do you have with us?<span class='asterisk'>*</span>",
          options: ["Pay Monthly", "Pay as you go"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2027-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['chat-reason'].indexOf('Network') > -1 && api.forms['relating-to-what'].indexOf('Pay Monthly') > -1",
          target: "node::2562"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Network') > -1 && api.forms['relating-to-what'].indexOf('Pay as you go') > -1",
          target: "node::2563"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Billing') > -1 && api.forms['relating-to-what'].indexOf('Pay Monthly') > -1",
          target: "node::2564"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Billing') > -1 && api.forms['relating-to-what'].indexOf('Pay as you go') > -1",
          target: "node::2565"
        }, {
          condition: "((api.forms['chat-reason'].indexOf('Network') > -1 || api.forms['chat-reason'].indexOf('Billing') > -1) && api.forms['relating-to-what'].indexOf('Small Medium Business') > -1) || api.forms['chat-reason'].indexOf('Broadband') > -1 && api.forms['relating-to-what1'].indexOf('Business') > -1",
          target: "node::2566"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Broadband') > -1 && api.forms['relating-to-what1'].indexOf('Consumer') > -1",
          target: "node::2567"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Internet of Things') > -1",
          target: "node::2459"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Anything else') > -1 && api.forms['relating-to-what2'].indexOf('Pay Monthly') > -1",
          target: "node::2568"
        }, {
          condition: "api.forms['chat-reason'].indexOf('Anything else') > -1 && api.forms['relating-to-what2'].indexOf('Pay as you go') > -1",
          target: "node::2569"
        }, {
          condition: "(api.forms['chat-reason'].indexOf('Network') > -1) || (api.forms['chat-reason'].indexOf('Billing') > -1) && api.forms['relating-to-what'].indexOf('Broadband') > -1",
          target: "node::2551"
        }]
      }
    }
  };
});