define("Automatons/automatons/2110", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2110,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_SUI_Offsite",
      ariaEnabled: true,
      description: "CMRGEN-17354",
      initialNode: "node::2468",
      onPageLanding: function onPageLanding() {
        var _this = this;

        var chatNode = this.Automaton.getRecord("node::2469");
        var date = new Date();
        var dow = date.getUTCDay();
        //dow = 2;
        var hour = date.getUTCHours();
        //hour = 7;
        var hop_node = "template::2019";
        //console.log("DOW: " + dow);
        //console.log("hour: " + hour);

        if (api.automatonDataMap.hasOwnProperty('suiOffsiteConfig')) {
          try {
            (function () {
              var automatonConfig = api.automatonDataMap.suiOffsiteConfig;
              var ovfChatNode = _this.Automaton.getRecord("node::2472");
              var HOPNode = _this.Automaton.getRecord("node::2503");

              automatonConfig.forEach(function params(line, idx) {
                if (line.key === "bu") {
                  chatNode.chatRouter.businessUnit = line.value;
                }
                if (line.key === "ag") {
                  chatNode.chatRouter.agentGroup = line.value;
                  //console.log("AG: " + line.value);
                  if (line.value === "10007016") {
                    if (dow == 6 || dow == 5 && hour > 17 || dow == 0) {
                      // HOP Weekend
                      //console.log("Weekend's HOP");
                      hop_node = "template::2019";
                    } else if (hour < 9) {
                      // before HOP
                      //console.log("Day's HOP");
                      hop_node = "template::2019";
                    } else if (hour > 17) {
                      // Mon-Thu after HOP
                      //console.log("Tomorrow's HOP");
                      hop_node = "template::2019";
                    }
                  }
                  HOPNode.template = hop_node;
                }
                if (line.key === "qt") {
                  chatNode.chatRouter.qThreshold = line.value;
                  ovfChatNode.chatRouter.qThreshold = line.value;
                }
                if (line.key === "ovfbu") {
                  ovfChatNode.chatRouter.businessUnit = line.value;
                }
                if (line.key === "ovfag") {
                  ovfChatNode.chatRouter.agentGroup = line.value;
                }
              });
              if (ovfChatNode.chatRouter.businessUnit !== "" && ovfChatNode.chatRouter.agentGroup !== "") {
                chatNode.chatRouter.agentsBusyNode = "node::2472";
                chatNode.chatRouter.outsideHopNode = "node::2472";
              }
            })();
          } catch (e) {
            console.error("Error while configuring chat nodes: " + e);
          };
        }
      },
      style: "style::2028"
    }
  };
});