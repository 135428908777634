define("Automatons/nodes/2496", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2496,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_eSim - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "positions",
          type: "text",
          hidden: true,
          label: "Digit numbers?<span class='asterisk'>*</span>",
          value: ""
        }, {
          id: "digits",
          type: "text",
          label: "Answer to secret question or PIN?<span class='asterisk'>*</span>",
          masked: true,
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "account-name",
          type: "text",
          label: "Account Name<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters only"
          }
        }, {
          id: "company-first-line",
          type: "text",
          label: "First Line of company address<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters only"
          }
        }, {
          id: "company-pcode",
          type: "text",
          label: "Company Postcode<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters only"
          }
        }, {
          id: "email-address",
          type: "text",
          label: "Registered email address<span class='asterisk'>*</span>",
          placeholder: "Please enter a valid email",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$",
            patternError: "Please enter a valid e-mail address"
          }
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2042-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }

        function randomNumber(min, max) {
          return Math.floor(Math.random() * (max - min) + min);
        }
        var digit_one = randomNumber(1, 3);
        var digit_two_start = 2;
        if (digit_one > 1) {
          digit_two_start = digit_one + 1;
        }
        var digit_two = randomNumber(digit_two_start, 4);
        this.formify.fields[1].value = "PIN Digits: " + digit_one + " and " + digit_two;
        this.formify.fields[2].label = "Please enter digits " + digit_one + " and " + digit_two + " from your 4 digit pin<span class='asterisk'>*</span>";
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          target: "node::2497"
        }]
      }
    }
  };
});