define("Automatons/nodes/2006", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2006,
    type: "nodes",
    attributes: {
      name: "Form Closure",
      type: "Confirmation",
      isOutcomeNode: 1,
      panel: {
        height: 100
      },
      template: "template::2005"
    }
  };
});