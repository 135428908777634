define("Automatons/nodes/2438", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2438,
    type: "nodes",
    attributes: {
      name: "Vodafone UK - TOBi Outage - I want to upgrade",
      type: "chat",
      chatRouter: {
        agentGroup: 10006334,
        agentsBusyNode: "node::2013",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1.5
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';
        var emailAddress = typeof api.forms['email-address'] !== 'undefined' ? api.forms['email-address'] : '';
        var chatReason = typeof api.forms['chat-reason'] !== 'undefined' ? api.forms['chat-reason'] : '';

        var visitorAttributes = {};
        if (visitorName) {
          visitorAttributes["Name"] = visitorName;
        }
        if (phoneNumber) {
          visitorAttributes["CTN"] = phoneNumber;
        }
        if (emailAddress) {
          visitorAttributes["Email"] = emailAddress;
        }
        if (chatReason) {
          visitorAttributes["ChatReason"] = chatReason;
        }
        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

        // Include datapass variables in form data if present
        var formData = api.getFormData();

        var BPTIER = typeof api.automatonDataMap.BPTIER !== 'undefined' ? api.automatonDataMap.BPTIER : '';
        var VCO_REF = typeof api.automatonDataMap.VCO_REF !== 'undefined' ? api.automatonDataMap.VCO_REF : '';
        var nanoRepIntent = typeof api.automatonDataMap.nanoRepIntent !== 'undefined' ? api.automatonDataMap.nanoRepIntent : '';

        if (BPTIER) {
          formData = formData + '\n- BPTIER: ' + BPTIER;
        }
        if (VCO_REF) {
          formData = formData + '\n- VCO_REF: ' + VCO_REF;
        }
        if (nanoRepIntent) {
          formData = formData + '\n- nanoRepIntent: ' + nanoRepIntent;
        }

        // Setting customer name to use value in submitted form
        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        // Setting auto opener to nanoRepIntent if it exists, otherwise use default or the one defined in the chat node
        var autoOpener = this.autoOpener ? this.autoOpener : "Thanks for choosing to chat with us. An agent will be with you shortly";
        if (nanoRepIntent) autoOpener = nanoRepIntent;

        var chatReason = typeof api.forms['personal-chat-reason'] !== 'undefined' ? api.forms['personal-chat-reason'] : '';
        api.fireCustomEvent('TobiUpgradeIntent', {
          TobiUpgradeIntent: chatReason
        });

        api.engageChat({
          formData: formData,
          autoOpener: autoOpener
        });
      }
    }
  };
});