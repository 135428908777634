define("Automatons/nodes/2320", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2320,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_FDS_IPVPN - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('IPVPN CE Install') > -1",
          qThreshold: 999,
          target: "node::2315"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('IPVPN Customer Activation and Migration') > -1",
          qThreshold: 999,
          target: "node::2316"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('IPVPN Pre Build') > -1",
          qThreshold: 999,
          target: "node::2317"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('IPVPN Inflight Change') > -1",
          qThreshold: 999,
          target: "node::2318"
        }, {
          AA: true,
          condition: "api.forms['enquiry-reason'].indexOf('IPVPN Projects') > -1",
          qThreshold: 999,
          target: "node::2319"
        }]
      }
    }
  };
});