define("Automatons/nodes/2484", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2484,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_BusinessMobile - 50+ Lines - Redirect",
      type: "redirect",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Redirected to vodafone.co.uk/business/contact-us",
      redirectTarget: "current",
      redirectUrl: "https://www.vodafone.co.uk/business/contact-us"
    }
  };
});