define("Automatons/automatons/2058", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2058,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_VOXI_Mob",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-1872",
      initialNode: "node::2253",
      style: "style::2022"
    }
  };
});