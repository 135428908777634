define("Automatons/nodes/2447", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2447,
    type: "nodes",
    attributes: {
      name: "Vodafone UK - App Direct Pre-Chat Survey - 10+ Employees - Self-Help",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2043"
    }
  };
});