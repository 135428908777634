define("Automatons/nodes/2382", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2382,
    type: "nodes",
    attributes: {
      name: "Vodafone Broadband PCS - Track My Order - Redirect",
      type: "redirect",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Redirected to vodafone.co.uk/shop/existing-customers/track-my-order/",
      redirectTarget: "current",
      redirectUrl: "https://www.vodafone.co.uk/shop/existing-customers/track-my-order/"
    }
  };
});