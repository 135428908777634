define("Automatons/nodes/2183", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2183,
    type: "nodes",
    attributes: {
      name: "Prechat Form Chat Prototype",
      type: "Prototype",
      onEntry: function onEntry() {
        // Fire custom event for setting visitor attributes
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';
        var emailAddress = typeof api.forms['email-address'] !== 'undefined' ? api.forms['email-address'] : '';
        var company = typeof api.forms['company'] !== 'undefined' ? api.forms['company'] : '';
        var companyName = typeof api.forms['company-name'] !== 'undefined' ? api.forms['company-name'] : '';
        var chatReason = typeof api.forms['chat-reason'] !== 'undefined' ? api.forms['chat-reason'] : '';
        var personalchatReason = typeof api.forms['personal-chat-reason'] !== 'undefined' ? api.forms['personal-chat-reason'] : '';
        var businesschatReason = typeof api.forms['business-chat-reason'] !== 'undefined' ? api.forms['business-chat-reason'] : '';
        var enquirychatReason = typeof api.forms['enquiry-chat-reason'] !== 'undefined' ? api.forms['enquiry-chat-reason'] : '';
        var querychatReason = typeof api.forms['query-chat-reason'] !== 'undefined' ? api.forms['query-chat-reason'] : '';

        var visitorAttributes = {};
        if (visitorName) {
          visitorAttributes["Name"] = visitorName;
        }
        if (phoneNumber) {
          visitorAttributes["CTN"] = phoneNumber;
        }
        if (emailAddress) {
          visitorAttributes["Email"] = emailAddress;
        }
        if (company) {
          visitorAttributes["Company_Name"] = company;
        }
        if (companyName) {
          visitorAttributes["Company_Name"] = companyName;
        }
        if (chatReason) {
          visitorAttributes["ChatReason"] = chatReason;
        }
        if (personalchatReason) {
          visitorAttributes["ChatReason"] = personalchatReason;
        }
        if (businesschatReason) {
          visitorAttributes["ChatReason"] = businesschatReason;
        }
        if (enquirychatReason) {
          visitorAttributes["ChatReason"] = enquirychatReason;
        }
        if (querychatReason) {
          visitorAttributes["ChatReason"] = querychatReason;
        }
        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

        // Include datapass variables in form data if present
        var formData = api.getFormData();

        var BPTIER = typeof api.automatonDataMap.BPTIER !== 'undefined' ? api.automatonDataMap.BPTIER : '';
        var VCO_REF = typeof api.automatonDataMap.VCO_REF !== 'undefined' ? api.automatonDataMap.VCO_REF : '';
        var nanoRepIntent = typeof api.automatonDataMap.nanoRepIntent !== 'undefined' ? api.automatonDataMap.nanoRepIntent : '';

        if (BPTIER) {
          formData = formData + '\n- BPTIER: ' + BPTIER;
        }
        if (VCO_REF) {
          formData = formData + '\n- VCO_REF: ' + VCO_REF;
        }
        if (nanoRepIntent) {
          formData = formData + '\n- nanoRepIntent: ' + nanoRepIntent;
        }

        // Setting customer name to use value in submitted form
        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        // Setting auto opener to nanoRepIntent if it exists, otherwise use default or the one defined in the chat node
        var autoOpener = this.autoOpener ? this.autoOpener : "Thanks for choosing to chat with us. An agent will be with you shortly";
        if (nanoRepIntent) autoOpener = nanoRepIntent;
        var brName = api.flashVars.brName;
        var businessRules = "VOD-B-LC-VCO-O-R-Fixed-DT-C2C|VOD-B-LC-VCO-O-R-Service-DT-Animated_T60";
        var regEx = new RegExp(businessRules, "gi");

        if (brName.match(regEx)) autoOpener = "If you require a copy of this chat, you can click the envelope icon at the bottom of the chat and enter your email address to allow us to send you a copy of this transcript. If you require an unedited copy, please copy and paste from the chat box prior to the chat session ending. Once the chat session has ended you will not be able to retrieve a copy of the transcript.<br><br>Thanks for choosing to chat with us, an agent will be with you shortly.";

        api.engageChat({
          formData: formData,
          autoOpener: autoOpener
        });
      }
    }
  };
});