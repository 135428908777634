define("Automatons/nodes/2171", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2171,
    type: "nodes",
    attributes: {
      name: "VF_PCS_NOC - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['requested-group'].indexOf('UK CST CSOC NTx cst Supp') > -1",
          qThreshold: 999,
          target: "node::2075"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('UK CST CSOC Net IP Sec') > -1",
          qThreshold: 999,
          target: "node::2076"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('UK CST UKNOC SPEN') > -1",
          qThreshold: 999,
          target: "node::2077"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('UK CORE CSOC TRANSM') > -1",
          qThreshold: 999,
          target: "node::2078"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('UK CORE CSOC IP Trans OP') > -1",
          qThreshold: 999,
          target: "node::2079"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('UK CORE CSOC DATA VOD AC') > -1",
          qThreshold: 999,
          target: "node::2080"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('UK CORE CSOC IPacc 1Line') > -1",
          qThreshold: 999,
          target: "node::2081"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('INT CST DSO 1Line Data') > -1",
          qThreshold: 999,
          target: "node::2082"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('INT CST SS RTO IPD MON D') > -1",
          qThreshold: 999,
          target: "node::2083"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('INT CST INOC cst IP 2LN') > -1",
          qThreshold: 999,
          target: "node::2084"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('INT CST INOC cst Tx 2LN') > -1",
          qThreshold: 999,
          target: "node::2085"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('INT CST SS RTO VTX MD') > -1",
          qThreshold: 999,
          target: "node::2086"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('INT CST SS RTO Net DSL') > -1",
          qThreshold: 999,
          target: "node::2087"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('INT CST SS RTO VOD ACC') > -1",
          qThreshold: 999,
          target: "node::2088"
        }]
      }
    }
  };
});