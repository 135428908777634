define("Automatons/nodes/2260", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2260,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_SOS - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "store-code",
          type: "text",
          label: "What is your store code?",
          placeholder: "Type here"
        }, {
          id: "ctn-or-ban",
          type: "text",
          label: "CTN or BAN?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "issue-summary",
          type: "textarea",
          label: "Summary of the issue<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "passed-dpa",
          type: "select",
          label: "Have they passed DPA?<span class='asterisk'>*</span>",
          options: ["Yes", "No"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2059-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: {
          target: "node::2261"
        }
      }
    }
  };
});