define("Automatons/nodes/2340", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2340,
    type: "nodes",
    attributes: {
      name: "VF_PCS_CPW - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          qThreshold: 999,
          target: "node::2339"
        }]
      }
    }
  };
});