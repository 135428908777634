define("Automatons/automatons/2047", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2047,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_VCO_Login",
      ariaEnabled: true,
      description: "CMRASI-1769",
      initialNode: "node::2225",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});