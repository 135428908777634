define("Automatons/nodes/2054", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2054,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_OrderAss - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "email-address",
          type: "text",
          label: "Email Address<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+).([a-zA-Z]{2,5})$",
            patternError: "Please enter a valid e-mail address"
          }
        }, {
          id: "requested-group",
          type: "select",
          label: "Who do you need to speak to today?<span class='asterisk'>*</span>",
          options: ["Order Assurance", "Solution Centre UK"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "enquiry-reason",
          type: "select",
          label: "What is the reason for your enquiry?<span class='asterisk'>*</span>",
          options: ["Support getting a quotation", "I need help raising an order", "I need an update on an order", "I need help with an order that's in delivery", "I have an issue with an order that's been delivered", "Other"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "other-enquiry-details",
          type: "text",
          label: "If other please provide details",
          placeholder: "Type here"
        }, {
          id: "related-service",
          type: "select",
          label: "What type of service does this relate to?<span class='asterisk'>*</span>",
          options: ["Unified Communications", "Service Design", "Connectivity", "In-Building Coverage", "Other"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "other-service-details",
          type: "text",
          label: "If other please provide details",
          placeholder: "Type here"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2019-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['requested-group'].indexOf('Order Assurance') > -1",
          target: "node::2055"
        }, {
          condition: "api.forms['requested-group'].indexOf('Solution Centre UK') > -1",
          target: "node::2056"
        }]
      }
    }
  };
});