define("Automatons/nodes/2182", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2182,
    type: "nodes",
    attributes: {
      name: "VF_PCS_BusinessBroadband - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['number-of-lines'].indexOf('1-9') > -1 || api.forms['number-of-lines'].indexOf('1-9 (Mon-Fri, 8am-8pm; Weekends, 9am-6pm)') > -1",
          qThreshold: 1,
          target: "node::2151"
        }, {
          AA: true,
          condition: "api.forms['customer-status'].indexOf('I need help with my account') > -1",
          qThreshold: 1,
          target: "node::2453"
        }]
      }
    }
  };
});