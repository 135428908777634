define("Automatons/nodes/2539", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2539,
    type: "nodes",
    attributes: {
      name: "Vodafone OnePotal Pre-Chat Survey - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "company-name",
          type: "text",
          label: "What company are you contacting us on behalf of?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "reference-number",
          type: "text",
          label: "Reference Number",
          placeholder: "Type here"
        }, {
          id: "enquiry",
          type: "text",
          label: "What is the nature of your enquiry?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "relating-to-what",
          type: "select",
          label: "What is this relating to?<span class='asterisk'>*</span>",
          options: ["Service Request", "Vone incident", "Other incident"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2096-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
        if (api.automatonDataMap.QNAME === 'CSC: ESSI Service Desk' || api.automatonDataMap.QNAME === 'CSC:ESSI Service Desk' || api.automatonDataMap.QNAME === 'CSC: ESSI-UK Support' || api.automatonDataMap.QNAME === 'CSC: VPS Fixed Service Desk') {
          this.formify.fields[5].hidden = true;
          this.formify.fields[5].required = false;
        }
        if (api.automatonDataMap.QNAME === 'CSC: VONE-C Service Desk') {
          this.formify.fields[5].options = [];
          this.formify.fields[5].options[0] = "Service Request";
          this.formify.fields[5].options[1] = "Vone incident";
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.automatonDataMap.QNAME === 'CSC: ESSI Service Desk' || api.automatonDataMap.QNAME === 'CSC:ESSI Service Desk' || api.automatonDataMap.QNAME === 'CSC: ESSI-UK Support'",
          target: "node::2540"
        }, {
          condition: "(api.automatonDataMap.QNAME === 'CSC: RETAIL ENTERPRISE' || api.automatonDataMap.QNAME === 'CSC: Glasgow Uk Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Glasgow UK Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Atlas UK Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Atlas Service Desk') && api.forms['relating-to-what'].indexOf('Vone incident') > -1",
          target: "node::2541"
        }, {
          condition: "(api.automatonDataMap.QNAME === 'CSC: RETAIL ENTERPRISE' || api.automatonDataMap.QNAME === 'CSC: Glasgow Uk Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Glasgow UK Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Atlas UK Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Atlas Service Desk') && api.forms['relating-to-what'].indexOf('Other incident') > -1",
          target: "node::2542"
        }, {
          condition: "(api.automatonDataMap.QNAME === 'CSC: RETAIL ENTERPRISE' || api.automatonDataMap.QNAME === 'CSC: Glasgow Uk Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Glasgow UK Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Atlas UK Private Service Desk' || api.automatonDataMap.QNAME === 'CSC: Atlas Service Desk') && api.forms['relating-to-what'].indexOf('Service Request') > -1",
          target: "node::2543"
        }, {
          condition: "(api.automatonDataMap.QNAME === 'CSC: PUBLIC & GOVERNMENT' || api.automatonDataMap.QNAME === 'CSC: VONE-C Service Desk') && api.forms['relating-to-what'].indexOf('Vone incident') > -1",
          target: "node::2544"
        }, {
          condition: "api.automatonDataMap.QNAME === 'CSC: PUBLIC & GOVERNMENT' && api.forms['relating-to-what'].indexOf('Other incident') > -1",
          target: "node::2545"
        }, {
          condition: "(api.automatonDataMap.QNAME === 'CSC: PUBLIC & GOVERNMENT' || api.automatonDataMap.QNAME === 'CSC: VONE-C Service Desk') && api.forms['relating-to-what'].indexOf('Service Request') > -1",
          target: "node::2546"
        }, {
          condition: "api.automatonDataMap.QNAME === 'CSC: VPS Fixed Service Desk'",
          target: "node::2547"
        }]
      }
    }
  };
});