define("Automatons/nodes/2008", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2008,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_Basic_Thank_You",
      type: "thankyou",
      isOutcomeNode: 1,
      template: "template::2008"
    }
  };
});