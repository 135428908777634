define("Automatons/nodes/2247", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2247,
    type: "nodes",
    attributes: {
      name: "Vodafone_AppleWatch_Widget_Survey - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.automatonDataMap.isAppleWatchDevelopment === true && api.forms['chat-reason'].indexOf('I want to purchase the Apple Watch') > -1",
          qThreshold: 999,
          target: "node::2266"
        }, {
          AA: true,
          condition: "api.automatonDataMap.isAppleWatchDevelopment === true && api.forms['chat-reason'].indexOf('I want to purchase the latest iPhone') > -1",
          qThreshold: 999,
          target: "node::2266"
        }, {
          AA: true,
          condition: "api.automatonDataMap.isAppleWatchDevelopment === true && api.forms['chat-reason'].indexOf('Anything else') > -1",
          qThreshold: 999,
          target: "node::2267"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I want to purchase the Apple Watch') > -1",
          qThreshold: 999,
          target: "node::2244"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('I want to purchase the latest iPhone') > -1",
          qThreshold: 999,
          target: "node::2245"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Anything else') > -1",
          qThreshold: 1.2,
          target: "node::2246"
        }]
      }
    }
  };
});