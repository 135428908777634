define("Automatons/nodes/2630", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2630,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_OneNet - Initial - TEST",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name?<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[a-zA-Z0-9 ]*$",
            patternError: "Please use alphanumeric characters"
          }
        }, {
          id: "phone-number",
          type: "text",
          label: "Contact Number<span class='asterisk'>*</span>",
          placeholder: "Type here",
          required: true,
          requiredError: "This is required",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "requested-group",
          type: "select",
          label: "Please select the correct care team for the best service<span class='asterisk'>*</span>",
          options: ["Mobile Care Team", "ISP Care Team", "One Net Care Team", "One Net Business Technical Support Team"],
          placeholder: "Choose one",
          required: true,
          requiredError: "This is required"
        }, {
          id: "business-partner-name",
          type: "text",
          label: "Business Partner Name",
          placeholder: "Enter text here"
        }, {
          id: "company-name",
          type: "text",
          label: "Company Name",
          placeholder: "Enter text here"
        }, {
          id: "dealer-code",
          type: "text",
          label: "Dealer Code",
          placeholder: "Enter text here",
          validate: {
            pattern: "^[0-9]*$",
            patternError: "Please enter a valid number"
          }
        }, {
          id: "business-partner-email",
          type: "text",
          label: "Business Partner Email<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true,
          requiredError: "This is required"
        }, {
          id: "transcript",
          type: "checkbox",
          checked: "checked",
          inline: 1,
          label: "I want a transcript automatically sent to this email address",
          value: "Yes"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit"
      },
      initialFocusElement: "#inq-2036-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(380, 570);
        }
      },
      onExit: function onExit() {
        var emailAddress = api.forms['business-partner-email'];
        console.log("emailAddress: " + emailAddress);
        console.log("Email Transcript: " + api.forms['transcript']);
        if (emailAddress && api.forms['transcript'] == "Yes") {
          console.log("set flag");
          api.fireCustomEvent('SetTranscriptEmail');
          api.emailTranscript(emailAddress);
        }
        if (api.forms['transcript'] == "") {
          console.log("unset flag");
          api.fireCustomEvent('UnSetTranscriptEmail');
        }
      },
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        submit: [{
          condition: "api.forms['requested-group'].indexOf('Mobile Care Team') > -1 && api.automatonDataMap.BPTIER === 'Platinum'",
          target: "node::2120"
        }, {
          condition: "api.forms['requested-group'].indexOf('Mobile Care Team') > -1",
          target: "node::2119"
        }, {
          condition: "api.forms['requested-group'].indexOf('ISP Care Team') > -1",
          target: "node::2412"
        }, {
          condition: "api.forms['requested-group'].indexOf('One Net Care Team') > -1",
          target: "node::2358"
        }, {
          condition: "api.forms['requested-group'].indexOf('One Net Business Technical Support Team') > -1",
          target: "node::2359"
        }]
      }
    }
  };
});