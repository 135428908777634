define("Automatons/nodes/2010", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2010,
    type: "nodes",
    attributes: {
      name: "AP - Secure Password Form Initial",
      type: "agent-pushed",
      actions: {
        sendFormDataToAgent: {
          action: "sendFormDataToAgent"
        }
      },
      header: "",
      inputs: [{
        name: "New_PIN",
        display_masked: true,
        label: "Please enter your new PIN",
        mask: true,
        pattern: "^[0-9]*$",
        placeholder: "Enter number here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }, {
        name: "Reset_Word",
        label: "Please provide us with a memorable word in case you need to reset it in the future",
        mask: true,
        pattern: "^[a-zA-Z0-9]*$",
        placeholder: "Enter text here",
        required: true,
        required_message: "This is a required field",
        size: "flex-100"
      }],
      onEntryTransition: "checkIfAllowed",
      sub_header: "This is a secure form. Details provided here are protected.",
      template: "template::2010",
      transitions: {
        checkIfAllowed: {
          condition: "api.get('showSecureForm') === false",
          target: "node::2471"
        }
      }
    }
  };
});