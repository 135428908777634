define("Automatons/automatons/2025", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2025,
    type: "automatons",
    attributes: {
      name: "VF_PreChat_FixedDelivery",
      ariaEnabled: true,
      description: "CMRASI-1633",
      initialNode: "node::2069",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});