define("Automatons/nodes/2175", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2175,
    type: "nodes",
    attributes: {
      name: "VF_PCS_DealerSupport - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.automatonDataMap.BPTIER === 'Platinum'",
          qThreshold: 1.2,
          target: "node::2120"
        }, {
          AA: true,
          condition: "api.automatonDataMap.BPTIER !== 'Platinum'",
          qThreshold: 1.2,
          target: "node::2119"
        }]
      }
    }
  };
});