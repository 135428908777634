define("Automatons/nodes/2251", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2251,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_Sales_Mob_initial_node",
      type: "survey",
      heading: "Thanks for chatting with us",
      inputs: [{
        id: "recommendation-rate",
        type: "select",
        label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction with Vodafone Webchat, how likely are you to recommend Vodafone to your family and friends?<span class='asterisk'>*</span>",
        options: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0"],
        placeholder: "Select option",
        required: true
      }, {
        id: "satisfaction-rate",
        type: "select",
        label: "On a scale of 1-5, where 1 is the worst and 5 is the best, how satisfied are you overall with the service that our team member provided?<span class='asterisk'>*</span>",
        options: [{
          label: "5 - Best",
          value: "5"
        }, "4", "3", "2", {
          label: "1 - Worst",
          value: "1"
        }],
        placeholder: "Select option",
        required: true
      }, {
        id: "query-resolved",
        type: "select",
        label: "Has the team member you have been chatting with resolved your query? ( Yes / No )<span class='asterisk'>*</span>",
        options: ["Yes", "No"],
        placeholder: "Select option",
        required: true
      }, {
        id: "what-youve-done",
        type: "select",
        label: "What would you have done if you had not received assistance through Vodafone live chat?<span class='asterisk'>*</span>",
        options: ["Sent an eMail", "Made a call", "Gone to store", "Done nothing"],
        placeholder: "Select option",
        required: true
      }, {
        id: "would-you-purchased",
        type: "select",
        label: "Would you have purchased / left Vodafone today if you had not spoken to an advisor?<span class='asterisk'>*</span>",
        options: ["Yes, I would have purchased", "No, I wouldn't have purchased", "I would have left Vodafone", "I still intend to leave Vodafone"],
        placeholder: "Select option",
        required: true
      }, {
        id: "what-you-like",
        type: "textarea",
        label: "Please tell us a little about your chat experience today?",
        placeholder: "Type message..."
      }],
      proto: "node::2242",
      requiredText: "",
      subheading: "",
      template: "template::2029",
      transitions: {
        submit: {
          target: "node::2257"
        }
      }
    }
  };
});