define("Automatons/automatons/2013", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2013,
    type: "automatons",
    attributes: {
      name: "Vodafone Broadband Pre-Chat Survey",
      ariaEnabled: true,
      description: "CMRASI-1623",
      initialNode: "node::2034",
      proto: "automaton::2099",
      style: "style::2013"
    }
  };
});