define("Automatons/automatons/2006", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2006,
    type: "automatons",
    attributes: {
      name: "AP Form - Secure Payment Form",
      description: "CMRASI-1625",
      initialNode: "node::2009",
      proto: "automaton::2112",
      style: "style::2007"
    }
  };
});