define("Automatons/nodes/2162", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2162,
    type: "nodes",
    attributes: {
      name: "Sales PCS - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Get help with my phone, bill, or account') > -1 || api.forms['personal-chat-reason'].indexOf('Get help with my phone, bill, or account') > -1",
          qThreshold: 1.5,
          target: "node::2040"
        }]
      }
    }
  };
});