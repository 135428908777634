define("Automatons/automatons/2123", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2123,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_TranscriptOnlyRed",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRGEN-21140",
      initialNode: "node::2516",
      style: "style::2015"
    }
  };
});