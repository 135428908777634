define("Automatons/nodes/2393", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2393,
    type: "nodes",
    attributes: {
      name: "Support One Number Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006359,
        agentsBusyNode: "node::2029",
        businessUnit: 19001162,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 1,
        scriptTreeId: 12201265
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        // Fire custom event for setting visitor attributes
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';

        var visitorAttributes = {};

        if (visitorName) {
          visitorAttributes["Name"] = visitorName;
        }
        if (phoneNumber) {
          visitorAttributes["CTN"] = phoneNumber;
        }

        api.fireCustomEvent('UpdateVisitorAttribute', visitorAttributes);

        // Include datapass variables in form data if present
        var formData = api.getFormData();

        // Setting customer name to use value in submitted form
        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        // Setting auto opener to nanoRepIntent if it exists, otherwise use default
        var autoOpener = "Hi! How can I help you today?";

        api.engageChat({
          formData: formData,
          autoOpener: autoOpener,
          scriptTreeId: 12201265
        });
      }
    }
  };
});