define("Automatons/nodes/2448", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2448,
    type: "nodes",
    attributes: {
      name: "VOD-UK_PostChat_ChannelSteering_V3 - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "recommendation-rate",
          type: "select",
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, based on this interaction with TOBi, how likely are you to recommend Vodafone to your family and friends?",
          options: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "0"],
          placeholder: "Select option"
        }, {
          id: "satisfaction-rate",
          type: "select",
          dynamic: {
            check: "recommendation-rate",
            logic: "any",
            value: "10;9;8;7;6;5;4;3;2;1;0"
          },
          label: "On a scale of 0 to 10, where 0 is the worst and 10 is the best, how useful was the answer provided by TOBi?",
          options: [{
            label: "10 - Best",
            value: "10"
          }, "9", "8", "7", "6", "5", "4", "3", "2", "1", {
            label: "0 - Worst",
            value: "0"
          }],
          placeholder: "Select option"
        }, {
          id: "query-resolved",
          type: "select",
          dynamic: {
            check: "satisfaction-rate",
            logic: "any",
            value: "10;9;8;7;6;5;4;3;2;1;0"
          },
          label: "Has TOBi resolved your query?",
          options: ["Yes", "No"],
          placeholder: "Select option"
        }, {
          id: "query-unresolved-reason",
          type: "select",
          dynamic: {
            check: "query-resolved",
            value: "No"
          },
          label: "In order to help us improve this service, please tell us the reason for your rating",
          options: ["No answer", "Wrong answer", "TOBi disconnected", "Technical issue", "Repetitive answer", "Something else"],
          placeholder: "Select option"
        }, {
          id: "something-else-comment",
          type: "textarea",
          dynamic: {
            check: "query-unresolved-reason",
            value: "Something else"
          },
          hideLabel: true,
          label: "Something else comment",
          placeholder: "Please tell us more..."
        }],
        heading: "Thanks for chatting with us",
        next: "submit",
        requiredText: "",
        subheading: ""
      },
      template: "template::2032",
      transitions: {
        submit: {
          target: "node::2449"
        }
      }
    }
  };
});