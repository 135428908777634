define("Automatons/nodes/2091", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2091,
    type: "nodes",
    attributes: {
      name: "Vodafone - Smart Forms - Annual Upgrade Promise - Initial",
      type: "agent-pushed",
      formify: {
        fields: [{
          id: "device",
          type: "text",
          label: "<span><br/>Just to make sure you're eligible, please can you confirm the device you're looking to trade in as part of our Annual Upgrade Promise?</span>",
          placeholder: "Device",
          required: true,
          requiredError: "This is required"
        }, {
          id: "text",
          type: "label",
          label: "<span style='font-size:12px;font-weight:normal;color:#666;text-align:left;font-style:italic;line-height:14px;'><p>If for any reason a different device than the one on your account is returned, we will return the device to you and an Early Upgrade Fee would apply to your account</p><p>I just need to check some details about the device you would like to trade in Please can you confirm the following...</p></span>"
        }, {
          id: "device_switch_on_and_working",
          type: "select",
          label: "Does the device switch on and work?",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "device_screen_faulty_or_broken",
          type: "select",
          label: "Is the screen faulty or broken?",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "device_move_than_3_scratches_or_cracks",
          type: "select",
          label: "Are there any more than 3 scratches or cracks that are over 1cm on the case or screen?",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "device_damage_to_keys_or_buttons",
          type: "select",
          label: "Is there any damage to any keys or buttons?",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "text2",
          type: "label",
          label: "<span style='font-size:12px;font-weight:normal;color:#666;text-align:left;font-style:italic;line-height:14px;'><p>If the device is in poor working condition, we will return the device to you and an Early Upgrade Fee would apply to your account.</p><p>As you are returning your old phone to us as part of your Annual Upgrade Promise, you will not be able to use it as a Trade-in against any other propositions.</p></span>"
        }],
        footerText: "",
        heading: "Annual Upgrade Promise",
        next: "submit"
      },
      template: "template::2063",
      transitions: {
        submit: [{
          target: "node::2092"
        }]
      }
    }
  };
});