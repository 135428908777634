define("Automatons/nodes/2405", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2405,
    type: "nodes",
    attributes: {
      name: "Mobile Postchat Form Prototype - transcript",
      type: "Prototype",
      onEntry: function onEntry() {
        var $viewport = api.automaton.Automaton.View.$viewport;
        var automatonId = api.automaton.id;

        var transcript = window.top.inqFrame.Inq.PM.getVar('emailTranscript').z0();

        setTimeout(checkNext, 300);

        function checkNext() {
          if ($('.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', $viewport).is('.inq-' + automatonId + '-non-required')) {
            $('#inq-' + automatonId + '-btn-next', $viewport).removeAttr('disabled');
          }
        }

        $($viewport).on('change', '.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', function (e) {
          if ($('.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', $viewport).val().length === 0 && !$('.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', $viewport).is('.inq-' + automatonId + '-non-required')) {
            $('#inq-' + automatonId + '-btn-next', $viewport).attr("disabled", "disabled");
          } else {
            $('#inq-' + automatonId + '-btn-next', $viewport).removeAttr('disabled');
          }
        });

        $($viewport).on('click', '#inq-' + automatonId + '-btn-next', function (e) {
          var $currentQuestion = $('.inq-' + automatonId + '-question.inq-' + automatonId + '-current', $viewport);
          $currentQuestion.removeClass('inq-' + automatonId + '-current');
          var $nextQuestions = $currentQuestion.nextAll();
          var $nextQuestion = $nextQuestions.first();
          //console.log($nextQuestions);
          //console.log($nextQuestions[0].elements[0].name);
          if ($nextQuestions[0].elements[0].name === "transcript-email" && transcript == true) {
            //console.log("transcript email");
            $nextQuestions = $nextQuestion.nextAll();
            $nextQuestion = $nextQuestions.first();
            //console.log($nextQuestions[0].elements[0].name);
            api.fireCustomEvent('UnSetTranscriptEmail');
          }

          $nextQuestion.addClass('inq-' + automatonId + '-current');

          $('#inq-' + automatonId + '-btn-cancel', $viewport).css("display", "none");
          $('#inq-' + automatonId + '-btn-back', $viewport).css("display", "inline-block");

          if ($nextQuestions.length === 1) {
            $('#inq-' + automatonId + '-btn-submit', $viewport).css("display", "inline-block");
            $('#inq-' + automatonId + '-btn-next', $viewport).css("display", "none");
          } else {
            if ($('.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', $viewport).val().length !== 0 || $('.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', $viewport).is('.inq-' + automatonId + '-non-required')) {
              $('#inq-' + automatonId + '-btn-next', $viewport).removeAttr('disabled');
            } else {
              $('#inq-' + automatonId + '-btn-next', $viewport).attr("disabled", "disabled");
            }
          }
        });

        $($viewport).on('click', '#inq-' + automatonId + '-btn-back', function (e) {
          var $currentQuestion = $('.inq-' + automatonId + '-question.inq-' + automatonId + '-current', $viewport);
          $currentQuestion.removeClass('inq-' + automatonId + '-current');
          var $prevQuestions = $currentQuestion.prevAll();
          var $prevQuestion = $prevQuestions.first();
          $prevQuestion.addClass('inq-' + automatonId + '-current');

          $('#inq-' + automatonId + '-btn-back, #inq-' + automatonId + '-btn-next', $viewport).css("display", "inline-block");
          $('#inq-' + automatonId + '-btn-submit', $viewport).css("display", "none");

          if ($('.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', $viewport).val().length !== 0 || $('.inq-' + automatonId + '-current .inq-' + automatonId + '-formify-input', $viewport).is('.inq-' + automatonId + '-non-required')) {
            $('#inq-' + automatonId + '-btn-next', $viewport).removeAttr('disabled');
          }

          if ($prevQuestions.length === 1) {
            $('#inq-' + automatonId + '-btn-cancel', $viewport).css("display", "inline-block");
            $('#inq-' + automatonId + '-btn-back', $viewport).css("display", "none");
          }
        });
      }
    }
  };
});