define("Automatons/automatons/2124", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2124,
    type: "automatons",
    attributes: {
      name: "VOD-UK_PostChat_TranscriptOnlyRedMob",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRGEN-21140",
      initialNode: "node::2518",
      style: "style::2022"
    }
  };
});