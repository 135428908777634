define("Automatons/nodes/2487", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2487,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Retail - RTC-Credit_Asses_Business - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006958,
        agentsBusyNode: "node::2177",
        businessUnit: 19001168,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999
      },
      isOutcomeNode: 1,
      proto: "node::2183"
    }
  };
});