define("Automatons/nodes/2158", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2158,
    type: "nodes",
    attributes: {
      name: "Generic Service PCS - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2017",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Pay Monthly account') > -1",
          qThreshold: 1.5,
          target: "node::2021"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Pay as you go account') > -1",
          qThreshold: 1.5,
          target: "node::2022"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Small business account') > -1",
          qThreshold: 1.5,
          target: "node::2023"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Home Broadband account') > -1",
          qThreshold: 1.5,
          target: "node::2024"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Broadband Activation') > -1",
          qThreshold: 1.5,
          target: "node::2287"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Technical - Phone, Tablet & Watches') > -1",
          qThreshold: 1.5,
          target: "node::2025"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Technical - Broadband') > -1",
          qThreshold: 1.5,
          target: "node::2026"
        }, {
          AA: true,
          condition: "api.forms['chat-reason'].indexOf('Vodafone Passes') > -1",
          qThreshold: 1.5,
          target: "node::2028"
        }]
      }
    }
  };
});