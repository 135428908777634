define("Automatons/nodes/2335", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2335,
    type: "nodes",
    attributes: {
      name: "Vodafone Sales PCS - AUT-WatsonVA-Dev - Chat",
      type: "chat",
      chatRouter: {
        agentGroup: 10006495,
        agentsBusyNode: "node::2013",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2049",
        qThreshold: 999,
        scriptTreeId: 12201273
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {
        var visitorName = typeof api.forms['name'] !== 'undefined' ? api.forms['name'] : '';
        var phoneNumber = typeof api.forms['phone-number'] !== 'undefined' ? api.forms['phone-number'] : '';

        api.fireCustomEvent('UpdateVisitorAttribute', {
          Name: visitorName,
          CTN: phoneNumber
        });

        var formData = api.getFormData();
        var chatReason = typeof api.forms['chat-reason'] !== 'undefined' ? api.forms['chat-reason'] : '';

        api.fireCustomEvent('TobiUpgradeIntent', {
          TobiUpgradeIntent: chatReason
        });

        var customerName = visitorName || 'You';
        var customerFirstName = customerName.substr(0, customerName.indexOf(' ')) || customerName;
        customerFirstName = customerFirstName.charAt(0).toUpperCase() + customerFirstName.slice(1);
        formData = formData + '\nCustomerDisplayName: ' + customerFirstName + "\n";

        api.engageChat({
          formData: formData,
          autoOpener: chatReason
        });
      }
    }
  };
});