define("Automatons/nodes/2533", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2533,
    type: "nodes",
    attributes: {
      name: "VF_Name_EmailOnly_INTERNAL-Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "What is your name<span class='asterisk'>*</span>",
          placeholder: "Enter text here",
          required: true
        }, {
          id: "emailAddress",
          type: "text",
          label: "What is your email address*",
          placeholder: "Email address",
          required: true,
          validate: {
            pattern: "^$|^([a-zA-Z0-9_\\-\\.]+)@vodafone\\.([a-zA-Z]{2,5})(\\.([a-zA-Z]{2,65}))?$",
            patternError: "Please enter a valid Vodafone e-mail address"
          },
          value: ""
        }, {
          id: "lineManager",
          type: "text",
          label: "Line manager name*",
          placeholder: "Line manager name",
          required: true,
          value: ""
        }, {
          id: "laptopNumber",
          type: "text",
          label: "Laptop Number* ",
          placeholder: "Laptop number",
          required: true,
          tooltip: true,
          value: ""
        }, {
          id: "problemDescription",
          type: "text",
          label: "Please describe the problem you're facing* ",
          placeholder: "Please describe the problem you're facing",
          required: true,
          value: ""
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "Welcome to the UK Office IT Live Chat. We’re here to help you with any general IT queries as well as escalations for open tickets that are out of SLA.<br><br>If you need to speak to us for IT support please ensure you’ve raised a ticket via <a style='font-weight: bold; text-decoration:underline;' href='https://tobi.vodafone.com/' target='_blank'>TOBi</a> before starting a chat.<br><br>If you need a password reset you can visit <a style='font-weight: bold; text-decoration:underline;' href='https://tobi.vodafone.com/' target='_blank'>TOBi</a> even on a personal device."
      },
      initialFocusElement: "#inq-2011-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.showLayer("titleBar");
          api.resize(300, 490);
          api.position("bottom right");
          setTimeout(function () {
            api.showLayer("toolBar");
            api.showLayer("chatFooter");
            api.showLayer("acifCenter");
          }, 500);
        }
      },
      onEntryTransition: "checkProactiveOverlay",
      proto: "node::2411",
      template: "template::2011",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          target: "node::2534"
        }]
      }
    }
  };
});