define("Automatons/nodes/2177", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2177,
    type: "nodes",
    attributes: {
      name: "VF_PCS_Retail - Agents Unavailable",
      type: "unavailable",
      pollAgentAvailability: true,
      template: "template::2018",
      transitions: {
        pollAA: [{
          AA: true,
          condition: "api.forms['requested-group'].indexOf('HALO (Siebel) ONLY Process Support') > -1",
          qThreshold: 999,
          target: "node::2125"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('PAYM Care Billing Support') > -1",
          qThreshold: 999,
          target: "node::2126"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Customer Technical Support') > -1",
          qThreshold: 999,
          target: "node::2127"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Customer PAYG Support') > -1",
          qThreshold: 999,
          target: "node::2128"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Customer Porting Support') > -1",
          qThreshold: 999,
          target: "node::2396"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('HBB Care') > -1",
          qThreshold: 999,
          target: "node::2129"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('HBB Easy Switch - Process Support') > -1",
          qThreshold: 999,
          target: "node::2504"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Support for Small and Medium Business Customers') > -1",
          qThreshold: 999,
          target: "node::2130"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('V by Vodafone') > -1",
          qThreshold: 999,
          target: "node::2131"
        }, {
          AA: true,
          condition: "api.forms['requested-group'].indexOf('Anovo Escalations') > -1",
          qThreshold: 999,
          target: "node::2135"
        }]
      }
    }
  };
});